import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { useRecoilState, useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { faReceipt, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { backdropVariants, modalVariants } from '../../../../../../../utils';
import {
  addProductsAtom,
  orderDetailsModalAtom,
  searchAddProductsAtom,
  searchLoadingAtom,
  searchResultsAtom,
  showAddProductsAtom,
  notificationsAtom,
  orderAtom,
  storeAtom,
  serviceAtom,
  locationAtom,
  selectedOrderAtom
} from '../../../../../../../atoms/Atoms';
import {
  businessesSelector
} from '../../../../../../../atoms/Selectors';
import OrderDetails from './shared-components/OrderDetails';
import ActionButton from './shared-components/ActionButton';
import { Spinner } from '../../../../../../shared-components/Spinner';
import api from '../../../../../../../api/api';
import envConfig from '../../../../../../../envConfig';

function DetailsModal() {
  const { i18n, t } = useTranslation();
  const ref = useRef();
  const reorderRef = useRef();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [showModal, setShowModal] = useRecoilState(orderDetailsModalAtom);
  const [cart, setCart] = useRecoilState(orderAtom);
  const setSelectedOrder = useSetRecoilState(selectedOrderAtom);
  const setService = useSetRecoilState(serviceAtom);
  const setShowAddProducts = useSetRecoilState(showAddProductsAtom);
  const setSearchResults = useSetRecoilState(searchResultsAtom);
  const setSearchLoading = useSetRecoilState(searchLoadingAtom);
  const setValue = useSetRecoilState(searchAddProductsAtom);
  const [location, setLocation] = useRecoilState(locationAtom);
  const [order, setOrder] = useRecoilState(addProductsAtom);
  const [showReorderModal, setReorderShowModal] = useState(false);
  const [loadingReorder, setLoadingReorder] = useState(false);

  const stores = useRecoilValueLoadable(businessesSelector);
  const [store, setStore] = useRecoilState(storeAtom);

  const voidedItems = _.filter(showModal?.items, (i) => i.isVoided);

  const closeReorderModal = () => {
    setReorderShowModal(false);
  };

  const closeModal = () => {
    if (showReorderModal) {
      setReorderShowModal(false);
    } else {
      setShowModal(null);
      setSelectedOrder(null);
      setSearchResults([]);
      setOrder([]);
      setValue('');
      setSearchLoading(false);
      setShowAddProducts(false);
      // history.push('/profile/orders');
    }
  };

  const reorder = async (type) => {
    setLoadingReorder(true);
    let newCart = JSON.parse(JSON.stringify(cart));
    const s = _.filter(stores.contents.data.data.results,
      (tempStore) => tempStore.id === showModal.businessId)[0];
    if (!store || store.id !== s.id) {
      setNotifications([
        ...notifications,
        {
          title: t('store_changed'),
          description: t('store_changed_description'),
          error: false,
        },
      ]);  
      await setStore(s);
      await setLocation({ ...location, location: null });
      await setService(s.locations[0].isPickUpAvailable ? 0 : 1);
      await setOrder([]);
      newCart = [];
      await localStorage.setItem(
        `${envConfig.StorageKey}-store`,
        JSON.stringify(s)
      );
      await localStorage.removeItem(`${envConfig.StorageKey}-order`);
    }

    api.get(`order/reorder/${showModal.confirmationCode}`)
      .then((response) => {
        setLoadingReorder(false);
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('reorder_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);  
        } else {
          const tempItems = _.map(response.data.data.results, (i) => ({
            ...i,
            isEBT: i.isEbt
          }));
          for (let k = 0; k < showModal.items.length; k++) {
            const currentItem = showModal.items[k];
            if (type === 'all' ||
              (type === 'included' && !currentItem.isVoided) ||
              (type === 'voided' && currentItem.isVoided)) {
              const itemToAdd = _.filter(tempItems,
                (item) => item.id === currentItem.businessItemId)[0];
              
              let amount = 0;
              if (newCart.length > 0) {
                const filter = _.filter(newCart, (p) => p.id === itemToAdd.id);
                for (let i = 0; i < filter.length; i++) {
                  amount += filter[0].amount;
                }
              }

              if (amount === 0) {
                const tempProduct = JSON.parse(JSON.stringify(itemToAdd));
                if (tempProduct.hasRandomWeight) {
                  tempProduct.uuid = uuidv4();
                  tempProduct.amount = itemToAdd.minQuantity;
                  newCart = newCart.concat(tempProduct);
                } else {
                  for (let i = 0; i < tempProduct.minQuantity; i++) {
                    tempProduct.uuid = uuidv4();
                    tempProduct.amount = 1;
                    newCart = newCart.concat(tempProduct);
                  }
                }
              } else {
                const tempProduct = JSON.parse(JSON.stringify(itemToAdd));
                const index = _.findIndex(newCart, (o) => o.id === itemToAdd.id);
                if (index > -1) {
                    if (tempProduct.hasRandomWeight) {
                      if (newCart[index].amount + tempProduct.stepAmount
                        <= tempProduct.maxQuantity) {
                        newCart[index].amount += tempProduct.stepAmount;
                      }
                    } else {
                      const tempAmount = _.filter(newCart, (p) => p.id === itemToAdd.id).length;
                      if (tempAmount + 1 <= tempProduct.maxQuantity) {
                        tempProduct.uuid = uuidv4();
                        tempProduct.amount = 1;
                        newCart = newCart.concat(tempProduct);
                      }
                    }
                }
              }
            }
          }
          setCart(newCart);
          setNotifications([
            ...notifications,
            {
              title: t('success'),
              description: t('add_product_success'),
              error: false,
            },
          ]);  
        }
      })
      .catch((err) => {
        setLoadingReorder(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('reorder_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      })
  };

  useClickAway(ref, () => {
    closeModal();
  });

  useClickAway(reorderRef, () => {
    closeReorderModal();
  });

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              ref={ref}
              className="flex flex-col bg-white rounded-xl mx-auto w-5/6 h-5/6"
            >
              <header className="flex items-center justify-between bg-accent text-white rounded-t-xl px-4 py-6">
                <div className="flex items-center space-x-1 font-bold tracking-tight text-xl leading-tight">
                  <FontAwesomeIcon icon={faReceipt} />
                  <div>{t('order')} #{showModal.confirmationCode}</div>
                </div>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="text-xl leading-none ring-0 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              </header>
              <main className="group relative flex flex-1 overflow-y-auto bg-gray-50 rounded-b-xl">
                <OrderDetails />
                {order && order.length > 0 && <ActionButton />}
              </main>
            </div>
          </motion.div>
        </motion.div>
      )}
      {showReorderModal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          key="reorder"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              ref={reorderRef}
              className="flex flex-col bg-white rounded-xl mx-auto"
            >
              <header className="flex items-center justify-between bg-gray-800 text-white rounded-t-xl px-4 py-6">
                <div className="flex items-center space-x-1 font-bold tracking-tight text-xl leading-tight">
                  <FontAwesomeIcon icon={faReceipt} />
                  <div>{t('reorder')}</div>
                </div>
                <button
                  type="button"
                  onClick={() => closeReorderModal()}
                  className="text-xl leading-none ring-0 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              </header>
              {loadingReorder && <Spinner className="mx-auto mt-2" />}
              <main className="group relative flex flex-1 overflow-y-auto bg-gray-50 rounded-b-xl p-4">
                <div className="flow">
                  <div className="flex-col">
                    <button
                      disabled={loadingReorder}
                      className="py-2 px-4 w-full rounded-2xl font-semibold text-white bg-primary disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-accent transition duration-500 ease-in-out outline-none focus:outline-none"
                      type="button"
                      onClick={() => reorder('all')}
                    >
                      All Items
                    </button>
                  </div>
                  <div className="flex-col">
                    <button
                      disabled={loadingReorder}
                      className="my-2 py-2 px-4 w-full rounded-2xl font-semibold text-white bg-primary disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-accent transition duration-500 ease-in-out outline-none focus:outline-none"
                      type="button"
                      onClick={() => reorder('included')}
                    >
                      Included Items
                    </button>
                  </div>
                  <div className="flex-col">
                    <button
                      disabled={loadingReorder}
                      className="py-2 px-4 w-full rounded-2xl font-semibold text-white bg-primary disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-accent transition duration-500 ease-in-out outline-none focus:outline-none"
                      type="button"
                      onClick={() => reorder('voided')}
                    >
                      Voided Items
                    </button>
                  </div>
                </div>
              </main>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default DetailsModal;
