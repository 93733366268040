import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import envConfig from '../../../envConfig';
import Faq1 from './shared-components/Faq1';
import Faq2 from './shared-components/Faq2';
import Faq3 from './shared-components/Faq3';

function Faq() {
    const { t } = useTranslation();
   if (envConfig.HeaderVersion === '1') {
    return <Faq1 />
}
if (envConfig.HeaderVersion === '2') {
  return <Faq2 />
}
if (envConfig.HeaderVersion === '3') {
    return <Faq3 />
  }
return <Faq1 />
}

export default Faq;
