import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline';
import { t } from 'i18next';

const faqs = [
    {
        question: `${t('faqQ1')}`,
        answer: `${t('faqA1')}`,
    },
    {
        question: `${t('faqQ2')}`,
        answer: `${t('faqA2')}`,
    },
    {
        question: `${t('faqQ3')}`,
        answer: `${t('faqA3')}`,
    },
    {
        question: `${t('faqQ4')}`,
        answer: `${t('faqA4')}`,
    },
    {
        question: `${t('faqQ5')}`,
        answer: `${t('faqA5')}`,
    },
    {
        question: `${t('faqQ6')}`,
        answer: `${t('faqA6')}`,
    },
    {
        question: `${t('faqQ7')}`,
        answer: `${t('faqA7')}`,
    },
    {
        question: `${t('faqQ8')}`,
        answer: `${t('faqA8')}`,
    },
    {
        question: `${t('faqQ9')}`,
        answer: `${t('faqA9')}`,
    },
    {
        question: `${t('faqQ10')}`,
        answer: `${t('faqA10')}`,
    },
    // More questions...
  ]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

function Faq2() {
    return (
        <div className="bg-gray-50 overflow-auto">
          <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
              <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {t('faq')}
              </h2>
              <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt className="text-lg">
                          <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                            <span className="font-medium text-gray-900">{faq.question}</span>
                            <span className="ml-6 flex h-7 items-center">
                              <ChevronDownIcon
                                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base text-gray-500">{faq.answer}</p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div>
        </div>  
    )
}
export default Faq2;
