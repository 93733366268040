import React from 'react';
import { useTranslation } from 'react-i18next';
import envConfig from '../../../envConfig';
import Welcome1 from './shared-components/Welcome1';
import Welcome2 from './shared-components/Welcome2';
import Welcome3 from './shared-components/Welcome3';

function Welcome() {
  const { i18n } = useTranslation();
if (envConfig.WelcomeVersion === '1') {
    return <Welcome1 />
}
if (envConfig.WelcomeVersion === '2') {
  return <Welcome2 />
}
if (envConfig.WelcomeVersion === '3') {
  return <Welcome3 />
}

return (
  <div className="my-2 text-sm sm:text-md w-full">
    <div className={envConfig.TemplateStyle === 'pill' ? 'self-center border border-2xl rounded-xl shadow bg-accentdark overflow-hidden' : 'self-center border border-2xl shadow bg-accentdark overflow-hidden'}>
      <div
        className="text-white text-justify font-light mx-2 my-1 tracking-wide text-ellipsis hidden md:block h-auto"
      >{i18n.language === 'en' ? envConfig.WelcomeLongEn : envConfig.WelcomeLongEs}
      </div>
      <div 
        className="text-white text-justify font-light mx-2 my-2 self-center md:hidden h-auto"     
      >{i18n.language === 'en' ? envConfig.WelcomeShortEn : envConfig.WelcomeShortEs}
      </div>
    </div>
  </div>)
}
export default Welcome;

