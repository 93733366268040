import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocalStorage } from 'react-use';
import { motion } from 'framer-motion';
import InfiniteScroll from 'react-infinite-scroller';
import {
  faBan,
  faPauseCircle,
  faPersonCarry,
  faReceipt,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  notificationsAtom,
  profileOrdersAtom,
  orderHistoryAtom,
  pageAtom,
  sizeAtom,
} from '../../../../../atoms/Atoms';
import api from '../../../../../api/api';
import Order from './shared-components/Order';
import Loader from '../../../../shared-components/loader/Loader';
import { H3 } from '../../../../shared-components/typography/Title';
import Panel from '../../../../shared-components/panel/Panel';
import envConfig from '../../../../../envConfig';

function Orders() {
  const { i18n, t } = useTranslation();
  const [orders, setOrders] = useRecoilState(profileOrdersAtom);
  const [page, setPage] = useRecoilState(pageAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const size = useRecoilValue(sizeAtom);
  const orderHistory = useRecoilValue(orderHistoryAtom);
  const [token] = useLocalStorage(`${envConfig.StorageKey}-token`);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const getOrders = () => {
    if (token && !loading) {
      setLoading(true);
      api
        .post(
          '/user/orders',
          {
            Page: page,
            Size: size,
            Query: '',
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description:
                `${t('orders_load_error')}`,
                error: true,
              },
            ]);
          } else if (response.data.data.results.length === 0) {
            setHasMore(false);
          } else {
            if (!orders) {
              setOrders(response.data.data.results);
            } else {
              setOrders([...orders, ...response.data.data.results]);
            }
            setPage(page + 1);
          }
        })
        .catch((error) => {
          setLoading(false);
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: `${t('orders_load_error')} - ${error.message}`,
              error: true,
            },
          ]);
        });
    }
  };

  useEffect(() => {
    getOrders();
  }, [token]);

  const containerVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const itemVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <div className="flex flex-col flex-1 overflow-y-auto text-sm text-gray-500">
      <div className="px-3">
        <Panel>
          <H3 text={t('order_summary')} />
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 py-4">
            <div className="shadow rounded-xl flex h-20">
              <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-amber-400 w-1/4 rounded-l-xl">
                <FontAwesomeIcon icon={faReceipt} />
              </div>
              <div className="flex flex-col justify-center p-4 text-xs text-gray-800">
                <div className="font-semibold">{t('orders')}</div>
                <div>{t('total')}: {orderHistory.totalOrders}</div>
              </div>
            </div>
            <div className="shadow rounded-xl flex h-20">
              <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-accent w-1/4 rounded-l-xl">
                <FontAwesomeIcon icon={faBan} />
              </div>
              <div className="flex flex-col justify-center p-4 text-xs text-gray-800">
                <div className="font-semibold">{t('cancelled')}</div>
                <div>
                  {t('count')}:{' '}
                  {orderHistory.cancelledOrders}
                </div>
              </div>
            </div>
            <div className="shadow rounded-xl flex h-20">
              <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-accent w-1/4 rounded-l-xl">
                <FontAwesomeIcon icon={faPauseCircle} />
              </div>
              <div className="flex flex-col justify-center p-4 text-xs text-gray-800">
                <div className="font-semibold">{t('orders_processing')}</div>
                <div>
                  {t('count')}:{' '}
                  {orderHistory.pendingOrders}
                </div>
              </div>
            </div>
            <div className="shadow rounded-xl flex h-20">
              <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-cyan-500 w-1/4 rounded-l-xl">
                <FontAwesomeIcon icon={faPersonCarry} />
              </div>
              <div className="flex flex-col justify-center p-4 text-xs text-gray-800">
                <div className="font-semibold">{t('completed')}</div>
                <div>
                  {t('count')}:{' '}
                  {orderHistory.completedOrders}
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </div>

      <motion.div
        initial={false}
        animate={orders && orders.length > 0 ? 'open' : 'closed'}
      >
        <InfiniteScroll
          pageStart={page}
          scrollThreshold={0.25}
          loadMore={getOrders}
          hasMore={hasMore}
          loader={
            <div key={0} className="p-4 flex flex-col flex-1 items-center justify-center">
              <Loader color="bg-loader" />
            </div>
          }
          useWindow={false}
        >
          {orders ? (
            <motion.div variants={containerVariants} className="divide-y">
              {_.map(orders, (o) => (
                <motion.div variants={itemVariants} key={o.id}>
                  <Order order={o} />
                </motion.div>
              ))}
            </motion.div>
          ) : (
            <div />
          )}
        </InfiniteScroll>
      </motion.div>
    </div>
  );
}

export default Orders;
