import React from 'react';

function SpecialPrice({ price, hasRandomWeight, regularPrice, savings, isUnitPriceMethod }) {
  if (hasRandomWeight) {
    return (
      <div className="font-extrabold font-light tracking-tight py-2">
        <div className="">${parseFloat(price).toFixed(2)} lb.</div>
      </div>
    );
  }

  return (
      <div className="flex flex-col items-start">   
        <div className="flex flex-row items-center">     
        <div className="font-extrabold text-3xl font-light tracking-tight text-accent mr-2 py-2">
          ${parseFloat(price).toFixed(2)}
        </div>
        <div 
          style={{ textDecoration: 'line-through' }}
          className="content-bottom text-sm font-extrabold text-gray-500 mt-2"
        >
          ${regularPrice?.toFixed(2)}
        </div>
        </div> 
        {isUnitPriceMethod && (
          <div className="text-md font-normal tracking-tight text-gray-40 mb-2">
            Ahorro de ${parseFloat(savings).toFixed(2)}
          </div>
        )}
      </div>
    
  );
}

export default SpecialPrice;
