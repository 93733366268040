import React, { useRef } from 'react';
import { useRecoilState } from 'recoil';
import { useClickAway } from 'react-use';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faArrowToRight,
  faArrowToLeft
} from '@fortawesome/pro-duotone-svg-icons';
import { shopperPagesAtom, selectedShopperPageAtom } from '../../../../atoms/Atoms';
import { backdropVariants } from '../../../../utils';

function Shopper() {
  const ref = useRef();
  const { t } = useTranslation();
  const [selectedShopperPage, setSelectedShopperPage] = useRecoilState(selectedShopperPageAtom);
  const [shopperPages, setShopperPages] = useRecoilState(shopperPagesAtom);

  useClickAway(ref, () => {
    // setSelectedShopperPage(null);
  });

  return (
    <AnimatePresence>
      {selectedShopperPage && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{ zIndex: 1001 }}
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-center md:items-center justify-center px-0 md:px-4"
        >
          <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="my-auto mx-auto w-full md:px-32 xl:w-2/3 px-4">
              <div className="flex items-center md:mt-10 justify-end">
                <button
                  type="button"
                  onClick={() => {
                    setSelectedShopperPage(null);
                  }}
                  className="hover:opacity-75 absolute -mt-5 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} className="text-white" style={{ fontSize: '42px' }} />
                </button>
              </div>
              <div className="flex justify-center items-center">
                <button
                  type="button"
                  onClick={() => {
                    let tempIndex = selectedShopperPage.index - 1;
                    if (tempIndex < 0) {
                      tempIndex *= -1;
                    }
                    setSelectedShopperPage(shopperPages[tempIndex % shopperPages.length]);
                  }}
                  className="hidden md:flex items-center justify-center mr-2 p-2 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-customYellow"
                >
                  <FontAwesomeIcon icon={faArrowToLeft} style={{ fontSize: '40px' }} />
                </button>
                <img
                  ref={ref}
                  alt={`${t('page')}: ${selectedShopperPage.index}`}
                  src={selectedShopperPage.image}
                  className="h-full w-auto flex-col pt-4 my-auto mx-auto ease-out"
                />
                <button
                  type="button"
                  onClick={() => {
                    let tempIndex = selectedShopperPage.index + 1;
                    if (tempIndex < 0) {
                      tempIndex *= -1;
                    }
                    setSelectedShopperPage(shopperPages[tempIndex % shopperPages.length]);
                  }}
                  className="hidden md:flex items-center justify-center ml-2 p-2 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-customYellow"
                >
                  <FontAwesomeIcon icon={faArrowToRight} style={{ fontSize: '40px' }} />
                </button>
              </div>
              <div className="flex md:hidden mt-2 justify-center">
                <button
                  type="button"
                  onClick={() => {
                    let tempIndex = selectedShopperPage.index - 1;
                    if (tempIndex < 0) {
                      tempIndex *= -1;
                    }
                    setSelectedShopperPage(shopperPages[tempIndex % shopperPages.length]);
                  }}
                  className="flex md:hidden items-center justify-center mr-2 p-2 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-customYellow"
                >
                  <FontAwesomeIcon icon={faArrowToLeft} style={{ fontSize: '40px' }} />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    let tempIndex = selectedShopperPage.index + 1;
                    if (tempIndex < 0) {
                      tempIndex *= -1;
                    }
                    setSelectedShopperPage(shopperPages[tempIndex % shopperPages.length]);
                  }}
                  className="flex md:hidden items-center justify-center mr-2 p-2 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-customYellow"
                >
                  <FontAwesomeIcon icon={faArrowToRight} style={{ fontSize: '40px' }} />
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
} 

export default Shopper;
