import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowToLeft,
  faBadgeCheck,
  faChevronLeft,
  faChevronRight,
  faExclamation,
  faMapPin,
  faPencil,
  faStar,
} from '@fortawesome/pro-duotone-svg-icons';
import { ExclamationIcon } from '@heroicons/react/outline';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import {
  locationAtom,
  notificationsAtom,
  orderAtom,
  serviceAtom,
  serviceSelectionAtom,
  storeAtom,
  tokenAtom,
} from '../../../../atoms/Atoms';
import Loader from '../../loader/Loader';
import Button from '../../buttons/Button';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';


function SmallDevicesList() {
  const { t } = useTranslation();
  const history = useHistory();
  const token = useRecoilValue(tokenAtom);
  const [location, setLocation] = useRecoilState(locationAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const setService = useSetRecoilState(serviceAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [state, setState] = useState(null);
  const [open, setOpen] = useRecoilState(serviceSelectionAtom);

  const getBusiness = (e) => {
    setLocation({
      ...location,
      loadingBusinesses: true,
      businesses: [],
      page: 1,
    });

    api
      .get(`/businesses/${e.id}`)
      .then((response) => {
        if (response.data.error) {
          setLocation({
            ...location,
            loadingBusinesses: false,
            page: 1,
            businesses: [],
          });
          setNotifications([
            ...notifications,
            {
              title: t('oops'),
              description: response.data.error,
              error: true,
            },
          ]);
        } else {
          setLocation({
            ...location,
            loadingBusinesses: false,
            page: 1,
            businesses: response.data.data.results,
          });
        }
      })
      .catch((error) => {
        setLocation({
          ...location,
          loadingBusinesses: false,
          businesses: [],
        });
        setNotifications([
          ...notifications,
          {
            title: t('oops'),
            description: `${t('store_load_error')} ${error.message}`,
            error: true,
          },
        ]);
      });
  };

  const handleStore = async (e) => {
    if (store && order.length > 0 && store.id !== e.id) {
      setLocation({ ...location, alert: e });
    } else {
      await setStore(e);
      await setService(1);
      await setLocation({
        ...location,
        modal: false,
        page: 0,
        location: state,
      });
      await localStorage.setItem(`${envConfig.StorageKey}-service`, '1');
      await localStorage.setItem(`${envConfig.StorageKey}-store`, JSON.stringify(e));
      await localStorage.setItem(`${envConfig.StorageKey}-location`, JSON.stringify(state));
      if (!location.preventRedirect) {
        await history.push(`/stores/${e.id}`);
      }
      setOpen(false);
    }
  };

  const handleAlert = async (e) => {
    await setStore(e);
    await setService(1);
    await setOrder([]);
    await setLocation({
      ...location,
      alert: null,
      page: 0,
      modal: false,
      location: state,
    });
    await localStorage.setItem(`${envConfig.StorageKey}-store`, JSON.stringify(e));
    await localStorage.setItem(`${envConfig.StorageKey}-service`, '1');
    await localStorage.removeItem(`${envConfig.StorageKey}-order`);
    await localStorage.setItem(`${envConfig.StorageKey}-location`, JSON.stringify(state));
    await history.push(`/stores/${e.id}`);
  };

  if (location.page === 0) {
    return (
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
          },
          show: {
            opacity: 1,
            transition: {
              staggerChildren: 0.01,
            },
          },
        }}
        initial="hidden"
        animate="show"
        className="group relative flex flex-col overflow-hidden bg-white"
      >
        <button
          type="button"
          onClick={() => setLocation({ ...location, current: 'register-location' })}
          className="hidden absolute z-10 bottom-4 right-4 text-white font-semibold text-xs bg-accent hover:bg-accent h-12 w-12 rounded-full shadow-xl outline-none focus:outline-none ring-0 focus:ring-0"
        >
          <FontAwesomeIcon icon={faPencil} className="text-xl" />
        </button>
        <div className="flex items-center justify-between h-12 px-2 text-base bg-white">
          <motion.div
            initial={{ x: 10 }}
            animate={{ x: 0 }}
            exit={{ x: 10 }}
            className="font-medium text-md"
          >
            {t('my_locations')}
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                delay: 1,
              },
            }}
            exit={{ opacity: 0, scale: 0 }}
            className="text-xs font-medium flex items-center justify-center h-6 w-6 text-white bg-accent rounded-full"
          >
            {location.locations.length}
          </motion.div>
        </div>
        <div className="flex flex-col overflow-auto divide-y divide-base w-80">
          {_.map(location.locations, (l) => (
            <motion.button
              type="button"
              variants={{
                hidden: { x: '100%', opacity: 0 },
                show: { x: 0, opacity: 1 },
              }}
              key={l.id}
              whileTap={{ x: -5 }}
              className="h-auto flex items-center text-left w-full p-4 space-x-4 transition duration-500 ease-in-out bg-gray-100 hover:border hover-border-accent hover-border-3"
              onClick={() => {
                getBusiness(l);
                setState(l);
              }}
            >
              <FontAwesomeIcon
                icon={faMapPin}
                className="text-accent"
              />
              <div className="flex-1 text-sm truncate">
                <div className="font-medium">{l.name}</div>
                <div className="text-gray-400 truncate">{l.addressLine}</div>
              </div>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="text-xs text-gray-800"
              />
            </motion.button>
          ))} 
        </div>
        <button
          type="button"
          onClick={() => setLocation({ ...location, modal: true, current: 'register-location' })}
          className="z-10 text-white font-semibold text-xs bg-accent hover:bg-accent h-12 w-12 rounded-full shadow-xl outline-none focus:outline-none ring-0 focus:ring-0 mt-6"
        >
          <FontAwesomeIcon icon={faPencil} className="text-xl" />
        </button>       
      </motion.div>
    );
  }
  

  return (
    <motion.div
      initial={{ x: '-100%', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: '100%', opacity: 0 }}
      className="group relative flex flex-1 flex-col overflow-hidden w-80"
    >
      {location.alert && (
        <motion.div
          initial={{
            y: '100%',
          }}
          animate={{
            y: 0,
            transition: {
              delay: 0.5,
            },
          }}
          exit={{
            y: '100%',
          }}
          className="relative z-10 flex flex-col flex-1 items-center justify-center text-center bottom-0 bg-yellow-500 bg-opacity-95 h-full text-white p-4 rounded-xl"
        >
          <div className="text-xs">
            {t('change_store_prompt')}
          </div>
          <div className="space-x-2 mt-4">
            <Button
              text={t('cancel')}
              onClick={() => {
                setLocation({ ...location, alert: null });
              }}
              className="bg-red-800 hover:bg-red-700 w-20"
            />
            <Button
              text={t('accept')}
              onClick={() => handleAlert(location.alert)}
              className="bg-green-800 hover:bg-green-700 w-20"
            />
          </div>
        </motion.div>
      )}

      <div className="flex items-center justify-between h-16 px-4 text-white bg-white w-80">
        <motion.button
          type="button"
          initial={{ x: 10 }}
          animate={{ x: 0 }}
          exit={{ x: 10 }}
          whileTap={{ x: -5 }}
          onClick={() => setLocation({ ...location, page: 0, businesses: [] })}
          className="flex items-center space-x-2 text-white outline-none focus:outline-none ring-0 focus:ring-0"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="text-md text-black" />
          <div className="font-medium text-md text-base">{t('back')}</div>
        </motion.button>
        {!location.loadingBusinesses && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                delay: 1,
              },
            }}
            exit={{ opacity: 0, scale: 0 }}
            className="text-xs font-medium flex items-center justify-center h-6 w-6 text-white bg-rose-500 rounded-full"
          >
            {location.businesses.length}
          </motion.div>
        )}
        {location.loadingBusinesses && <Loader className="bg-white" />}
      </div>

      {/* Not loading and no results */}
      {!location.loadingBusinesses && location.businesses.length === 0 && (
        <div className="flex flex-col flex-1 items-center justify-center text-sm tracking-tight p-4">
           <FontAwesomeIcon icon={faExclamation} className="text-4xl text-yellowalert" />
          <div className="text-start text-gray-500 mt-4">
            {t('no_valid_location')}
          </div>
          <div className="text-start text-gray-500 mt-4">
            {t('try_pickup_instead')}
          </div>
        </div>
      )}

      {/* Not loading and results */}
      <AnimatePresence>
        {!location.loadingBusinesses && location.businesses.length > 0 && (
          <div className="flex flex-col flex-1 text-sm tracking-tight overflow-y-auto divide-y divide-gray-100 w-80">
            {_.map(location.businesses, (e) => (
              <motion.button
                type="button"
                key={e.id}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
                whileTap={{ x: -5 }}
                disabled={location.alert}
                onClick={() => handleStore(e)}
                className="disabled:cursor-not-allowed bg-gray-100 hover:border hover-border-accent hover-border-3 flex items-center h-16 lex items-center text-xs outline-none p-4 space-x-4 focus:outline-none ring-0 focus:ring-0 transition duration-200 ease-in-out"
              >
                <FontAwesomeIcon
                  icon={faStar}
                  className={
                    store?.id === e.id ? 'text-amber-400' : 'text-gray-300'
                  }
                />
                <div className="flex-1 text-left truncate">
                  <div className="font-medium truncate text-sm">{e.name}</div>
                  <div className="text-gray-500 truncate">
                    {t('order_minimum')}: ${e.minimumDeliveryOrderAmount.toFixed(2)}
                    /{t('service_cost')}: $
                    {e.locations[0].deliveryFee.toFixed(2)}
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faBadgeCheck}
                  className="text-xs text-gray-400"
                />
              </motion.button>
            ))}
          </div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default SmallDevicesList;
