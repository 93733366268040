import React, { useRef, useState } from 'react';

function Image({ image, name }) {
  const imageRef = useRef(null);
  const [state, setState] = useState({
    mouseX: null,
    mouseY: null,
  });

  const handleMouseMovement = (e) => {
    const { left, top } = imageRef.current.getBoundingClientRect();

    const x =
      ((e.pageX - left) / parseInt(imageRef.current.clientWidth, 10)) * 100;
    const y =
      ((e.pageY - top) / parseInt(imageRef.current.clientHeight, 10)) * 100;

    setState({
      mouseX: x,
      mouseY: y,
    });
  };

  return (
    <figure
      className="flex flex-1 items-center justify-center h-40 md:h-64 w-full rounded-xl overflow-hidden cursor-zoom"
      onMouseMove={handleMouseMovement}
    >
      <img
        ref={imageRef}
        alt={name}
        src={image}
        className="h-full w-auto transform ease-out duration-500 hover:scale-150"
        style={{
          transformOrigin: `${state.mouseX}% ${state.mouseY}%`,
        }}
      />
    </figure>
  );
}

export default Image;
