import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { useUnmount, useMount } from 'react-use';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Banner from './banner/Banner';
import { locationAtom, notificationsAtom, popupAtom, storeAtom, storesDialogAtom } from '../../../../atoms/Atoms';
import FeedbackFooter from '../../../shared-components/feedback-footer/FeedbackFooter';
import Welcome from '../../../shared-components/welcome/Welcome';
import ServiceSelection from '../../../shared-components/service-selection/ServiceSelection';
import ServicesDrawer from '../../../shared-components/service-selection/shared-components/ServicesDrawer';
import InfoFooter from '../../../shared-components/info-footer/InfoFooter';
import SocialFooter from '../../../shared-components/social-footer/SocialFooter';
import GopanzaFooter from '../../../shared-components/gopanza-footer/GopanzaFooter';
import BrandCarousel from '../../../shared-components/brand-carousel/BrandCarousel';
import SponsoredCarousel from '../../../shared-components/sponsored-carousel/SponsoredCarousel';
import InfocardCarousel from '../../../shared-components/infocard-carousel/InfocardCarousel';
import ShopperButton from './ShopperButton';
import Bags from '../../../../assets/img/bags.jpg';
import Subheader1 from '../../../shared-components/subheader/shared-components/Subheader2';

function Home2() {
  const { t } = useTranslation();
  const setDialog = useSetRecoilState(storesDialogAtom);
  const [registerLocation, setRegisterLocation] = useRecoilState(locationAtom);
  const [showPopup, setShowPopup] = useRecoilState(popupAtom);
  const store = useRecoilValue(storeAtom);

  useUnmount(() => {
    setDialog(false);
  });

  useMount(() => {
    setShowPopup(true);
  });

  return (
  <div className="flex-1 overflow-y-auto bg-background">
  <motion.div
    initial={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    animate={{
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    exit={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
  >
    <ServicesDrawer />
    <div className="flex flex-col px-2 sm:px-12 md:px-24 lg:px-52 relative isolate overflow-hidden bg-white">
      <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
          <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            {t('welcome_concierge')}
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('welcome_concierge_desc')}
          </p>
          {store && (
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href={store ? `/stores/${store.locations[0].id}` : null}
                className="rounded-md bg-accent px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t('shop_now')}
              </a>
              {/* <a href="/" className="text-sm font-semibold leading-6 text-gray-900">
                Learn more <span aria-hidden="true">→</span>
              </a> */}
            </div>
          )}
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
              <img
                src={Bags}
                alt="App screenshot"
                width="100%"
                height="100%"
                className="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <FeedbackFooter />
    <SocialFooter />
    <InfoFooter />  
    <GopanzaFooter />
  </motion.div>
  </div>
  )
}

export default Home2;
