import React, { useState } from 'react';
import _ from 'lodash';
import {
  useRecoilValue,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import ItemsCarousel from 'react-items-carousel';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowToLeft,
  faArrowToRight,
} from '@fortawesome/pro-duotone-svg-icons';
import useBreakpoint from '../../../hooks/useBreakpoint';
import Loader from '../loader/Loader';
import { storeAtom } from '../../../atoms/Atoms';
import { infoCardsSelector } from '../../../atoms/Selectors';
import BrandCard from './BrandCard';


function BrandCarousel() {
  const { t } = useTranslation();
  const store = useRecoilValue(storeAtom);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const brandCards = useRecoilValueLoadable(infoCardsSelector);


  function itemsToShow(bp) {
    // console.log(bp);
    if (bp === 7) {
      return 3; 
    }
    if (bp === 4) {
      return 2; 
    }
    if (bp === 3) {
      return 2; 
    }
    if (bp === 2) {
      return 1; 
    }
    return 1;
  }

  return (
    <div 
      hidden={brandCards && _.filter(brandCards.contents?.data?.data?.results, (i) => i.action.includes('action?url=brandsearch:')).length === 0}
      className="space-y-4 md:py-2 bg-background"
    >
      {brandCards.state === 'Loading' && (
        <div className="flex flex-1 flex-col items-center justify-center h-20">
          <Loader />
        </div>
      )}
      {brandCards.state === 'hasValue' && brandCards.contents?.data?.data?.results?.length > 0 && (
        <div>
          <div className="mx-2 md:px-4">
            {brandCards.contents?.data?.data?.results?.length}
            <div className="font-bold text-md md:text-2xl md:mt-2 md:mb-2">
              {t('brands_header')}
            </div>
          </div>
          <div>
          <ItemsCarousel
            infiniteLoop
            gutter={10}
            activePosition="right"
            chevronWidth={50}
            disableSwipe={false}
            alwaysShowChevrons
            numberOfCards={itemsToShow(breakpoint)}
            slidesToScroll={itemsToShow(breakpoint)}
            showSlither
            firstAndLastGutter
            activeItemIndex={activeItemIndex}
            requestToChangeActive={setActiveItemIndex}
            rightChevron={
              <button
                type="button"
                className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full hover:bg-customYellow"
              >
                <FontAwesomeIcon icon={faArrowToRight} />
              </button>
            }
            leftChevron={
              <button
                type="button"
                className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full hover:bg-customYellow"
              >
                <FontAwesomeIcon icon={faArrowToLeft} />
              </button>
            }
          >
            {brandCards.state === 'hasValue' &&
              _.map(_.filter(brandCards.contents?.data?.data?.results, (i) => i.action.includes('action?url=brandsearch:')),
              (s, index) => (
               <BrandCard infocard={s} />
              )
            )}
          </ItemsCarousel>
          </div>
        </div>
      )}
    </div>
  );
}

export default BrandCarousel;
