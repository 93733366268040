import React from 'react';
import { motion } from 'framer-motion';

function EmptyCart({ className }) {
  return (
    <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="896"
        height="712"
        viewBox="0 0 896 712"
        className={className}
      >
        <g fill="none" transform="translate(0 .908)">
          <path
            fill="#FDA4AF"
            d="M41.634,676.83017 C54.06242,699.87917 80.44,709.77367 80.44,709.77367 C80.44,709.77367 86.66712,682.29824 74.2387,659.24919 C61.81028,636.20014 35.4327,626.30569 35.4327,626.30569 C35.4327,626.30569 29.20559,653.78112 41.634,676.83017 Z"
          />
          <motion.path
            fill="#F43F5E"
            d="M50.17653,669.24719 C72.61494,682.74688 81.25669,709.56099 81.25669,709.56099 C81.25669,709.56099 53.51857,714.48778 31.08016,700.98808 C8.64175,687.48838 0,660.67428 0,660.67428 C0,660.67428 27.73811,655.7475 50.17653,669.24719 Z"
          />
          <rect width="140" height="2" x="413.248" fill="#E4E4E7" />
          <rect width="2" height="18.5" x="513.249" y="1.5" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="452.248" y="1.5" fill="#A1A1AA" />
          <rect width="140" height="2" x="484.248" y="96" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="522.249" y="78" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="583.249" y="78" fill="#A1A1AA" />
          <rect width="140" height="2" x="670.249" y="141" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="708.249" y="123" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="769.249" y="123" fill="#A1A1AA" />
          <rect width="140" height="2" x="656.249" y="605" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="694.249" y="587" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="755.249" y="587" fill="#A1A1AA" />
          <rect width="140" height="2" x="417.248" y="284" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="455.248" y="266" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="516.249" y="266" fill="#A1A1AA" />
          <rect width="140" height="2" x="461.248" y="525" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="499.248" y="507" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="560.249" y="507" fill="#A1A1AA" />
          <rect width="140" height="2" x="685.249" y="452" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="723.249" y="434" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="784.249" y="434" fill="#A1A1AA" />
          <polygon
            fill="#27272A"
            points="362.06 666.276 125.274 666.276 125.274 664.573 360.356 664.573 360.356 581.953 145.18 581.953 134.727 560.176 136.263 559.439 146.252 580.249 362.06 580.249"
          />
          <circle fill="#27272A" cx="156.789" cy="690.125" r="17.887" />
          <circle cx="333.101" cy="690.125" r="17.887" fill="#27272A" />
          <circle cx="540.927" cy="310.245" r="11.073" fill="#27272A" />
          <path
            fill="#27272A"
            d="M387.38538,553.84539 L121.23673,553.84539 L63.64844,365.60892 L446.69256,365.60892 L446.34404,366.71645 L387.38538,553.84539 Z M122.49688,552.14189 L386.136,552.14189 L444.37017,367.31238 L65.95082,367.31238 L122.49688,552.14189 Z"
          />
          <polygon
            fill="#ECFEFF"
            points="132.842 544.05 82.26 377.107 418.701 377.107 418.395 378.09 366.61 544.05"
          />
          <polygon
            fill="#27272A"
            points="451.465 348.792 449.818 348.355 461.059 305.986 526.448 305.986 526.448 307.69 462.37 307.69"
          />
          <rect
            width="345.293"
            height="1.704"
            x="82.258"
            y="422.676"
            fill="#27272A"
          />
          <rect
            width="306.319"
            height="1.704"
            x="101.459"
            y="485.436"
            fill="#27272A"
          />
          <rect
            width="1.704"
            height="186.533"
            x="254.314"
            y="366.461"
            fill="#27272A"
          />
          <rect
            width="186.929"
            height="1.704"
            x="233.557"
            y="458.875"
            fill="#27272A"
            transform="rotate(-86.249 327.022 459.727)"
          />
          <rect
            width="1.704"
            height="186.929"
            x="182.457"
            y="366.263"
            fill="#27272A"
            transform="rotate(-3.729 183.31 459.727)"
          />
          <rect width="896" height="2" y="709.092" fill="#27272A" />
          <rect width="140" height="2" x="60.248" y="317" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="98.249" y="299" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="159.249" y="299" fill="#A1A1AA" />
          <rect width="140" height="2" x="109.249" y="21" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="209.249" y="22.5" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="148.249" y="22.5" fill="#A1A1AA" />
          <rect width="140" height="2" x="250.249" y="218" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="350.248" y="219.5" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="289.248" y="219.5" fill="#A1A1AA" />
          <rect width="140" height="2" x="12.248" y="217" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="112.249" y="218.5" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="51.248" y="218.5" fill="#A1A1AA" />
          <rect width="140" height="2" x="180.249" y="117" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="218.249" y="99" fill="#A1A1AA" />
          <rect width="2" height="18.5" x="279.248" y="99" fill="#A1A1AA" />
        </g>
      </svg>
    </motion.div>
  );
}

export default EmptyCart;
