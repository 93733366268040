import React, { useEffect } from 'react';
import _ from 'lodash';
// import Banner from '../../shared-components/banners/Banner';
import envConfig from '../../../envConfig';
import Checkout1 from './shared-components/CheckoutV1';
import Checkout2 from './shared-components/CheckoutV2';


function Checkout() {
  if (envConfig.StoreVersion === '1') {
    return (
      <Checkout1 />
    )
  } 
  if (envConfig.StoreVersion === '2') {
    return (
      <Checkout2 />
    )
  } 
  return (
    <Checkout2 />
  )
} 

export default Checkout;
