import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { notificationsAtom, tokenAtom } from '../../../atoms/Atoms';
import api from '../../../api/api';
import Loader from '../../shared-components/loader/Loader';

function PayPalAccept() {
  const token = useRecoilValue(tokenAtom);
  const history = useHistory();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loading, setLoading] = useState(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const { t } = useTranslation();

  useEffect(() => {
    if (query) {
      setLoading(true);
      const baToken = query.get('ba_token');
      api
        .post(`billingAgreement/accept/${baToken}`, null)
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: t('billing_agreement_error'),
                description: response.data.error,
                error: true,
              },
            ]);
            history.push('/checkout');
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('success'),
                description: response.data.message
                  ? response.data.message
                  : t('payment_successful'),
                error: false,
              },
            ]);
            history.push('/checkout');
          }
        })
        .catch((error) => {
          setNotifications([
            ...notifications,
            {
              title: 'Error ',
              description: t('payment_agreement_error')`${error}`,
              error: true,
            },
          ]);
          history.push('/checkout');
        });
    }
  }, []);
  return (
    <div>
      {loading && (
        <div className="mt-40 flex flex-col flex-1 items-center justify-center">
          <h1>{t('loading')}</h1>
          <Loader color="bg-gray-500" />
        </div>
      )}
    </div>
  );
}

export default PayPalAccept;
