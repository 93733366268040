import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../../api/api';
import Loader from '../../shared-components/loader/Loader';

function PayPalAppCancel() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const { t } = useTranslation();

  const query = useQuery();
  useEffect(() => {
    if (query) {
      const baToken = query.get('ba_token');
      if (baToken) {
        setLoading(true);
        api
          .post(`billingAgreement/cancel/${baToken}`, null)
          .then((response) => {
            setLoading(false);
            if (response.data.error) {
              setMessage(`${t('billing_agreement_cancel_error')} ${response.data.error}`);
            } else {
              setMessage(t('billing_agreement_cancel_success'));
            }
          })
          .catch((error) => {
            setLoading(false);
            setMessage(t('payment_agreement_error'));
          });
      }
    }
  }, []);
  return (
    <div>
      <div className="mt-40 mx-10 flex flex-col flex-1 items-center justify-center">
        <h1>{message}</h1>
        {loading && (
          <Loader color="bg-gray-500" />
        )}
        {!loading && (
          <button
            type="button"
            onClick={() => {
              window.location.href = '/appclose';
            }}
            className="text-sm p-4 rounded-xl border text-white bg-accent hover:bg-accent transition duration-500 ease-linear ring-0 ring-offset-0 focus:ring-0 outline-none focus:outline-none"
          >
            {t('close_page')}
          </button>
        )}
      </div>
    </div>
  );
}

export default PayPalAppCancel;
