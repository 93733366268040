import React, { useState } from 'react';
import _ from 'lodash';
import { useSetRecoilState } from 'recoil';
import { modalAtom, productAtom } from '../../../atoms/Atoms';
import Counter from '../Counter';
import ItemTimeConstraintWarning from '../ItemTimeConstraintWarning';
import SpecialsBanner from '../../../assets/img/specials/specials_banner.png';

function Card({
  id,
  name,
  image,
  price,
  small,
  description,
  brand,
  className,
  product,
}) {
  const [valid, setValid] = useState(true);
  const setModal = useSetRecoilState(modalAtom);
  const setProduct = useSetRecoilState(productAtom);
  return (
    <div>      
      <div
        style={{ position: 'relative', top: 0, left: 0 }}
        key={id}
        role="button"
        tabIndex={0}
        onKeyDown={() => {
          setModal(true);
          setProduct(product);
        }}
        onClick={() => {
          setModal(true);
          setProduct(product);
        }}
        className={`
          ${className} ${small ? 'h-36 md:h-40' : 'h-60 md:h-64'} flex flex-col items-center p-4 flex-shrink-0 bg-white rounded-xl border border-cardBorder w-full outline-none focus:outline-none
        `}
      >
        {image && valid ? (
          <div className="flex text-gray-200">            
            <div style={{ position: 'relative', top: 0, left: 0 }}>
              <img
                style={{ position: 'relative', top: 5, left: 0 }}
                alt={name}
                onError={() => {
                  setValid(false);
                }}
                src={image}
                className={`${small ? 'max-h-6 md:max-h-8' : 'max-h-20 md:max-h-24 min-h-20 md:min-h-24'} w-auto mx-auto my-auto mb-2`}
              />
            </div>
            <img 
              alt="special"
              style={product.hasActivePromo ? { position: 'absolute', top: 5, left: 10 } : { position: 'absolute', top: 10, left: 22 }}
              className={product.hasActivePromo ? 'h-4 md:h-6' : 'hidden'}
              src={SpecialsBanner}
            />  
          </div>
        ) : (
          <div className={`${small ? 'max-h-12 md:max-h-14' : 'max-h-20 md:max-h-24'} flex flex-1 items-center justify-center text-gray-200`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="512"
              height="512"
              viewBox="0 0 512 512"
              fill="currentColor"
              className={small ? 'h-12 w-12 md:h-14 md:w-14 p-2' : 'h-24 w-24 md:h-28 md:w-28 p-2'}
            >
              <g transform="translate(0 .004)">
                <path d="M424.17,80.6055451 C406.04,77.6055451 372.27,86.7855451 351.68,98.2955451 C334.68,88.7755451 309.23,81.2955451 290.53,80.5055451 C292.35,82.7155451 294.36,84.6755451 296.03,87.0855451 C316.87,117.235545 321.77,159.385545 318.2,191.995545 L478,191.995545 C483.25,152.525545 472.84,89.3455451 424.17,80.6055451 Z M210.53,50.4655451 C221.82,38.2755451 225,18.4355451 223.75,0.245545068 C210.87,-0.614454932 188.08,0.125545068 173.75,13.5255451 C157.2,30.1255451 160,59.8855451 160.51,63.7455451 C179,64.9955451 198.28,61.8955451 210.53,50.4655451 Z M402.22,50.4655451 C413.51,38.2755451 416.65,18.4655451 415.44,0.245545068 C402.56,-0.614454932 379.77,0.125545068 365.44,13.5255451 C348.91,30.1255451 351.67,59.8855451 352.22,63.7455451 C370.67,64.9955451 390,61.8955451 402.22,50.4655451 Z M232.48,80.6055451 C214.35,77.6055451 180.58,86.7855451 160,98.2955451 C139.34,86.7355451 105.57,77.5855451 87.52,80.6055451 C38.91,89.3355451 28.53,152.875545 33.7,191.995545 L286.3,191.995545 C291.56,152.525545 281.15,89.3455451 232.48,80.6055451 Z" />
                <path d="M496,223.995545 L16,223.995545 C7.163444,223.995545 0,231.158989 0,239.995545 L0,351.995545 L512,351.995545 L512,239.995545 C512,231.158989 504.836556,223.995545 496,223.995545 Z M64,303.995545 C55.163444,303.995545 48,296.832101 48,287.995545 C48,279.158989 55.163444,271.995545 64,271.995545 C72.836556,271.995545 80,279.158989 80,287.995545 C80,296.832101 72.836556,303.995545 64,303.995545 Z M448,303.995545 C439.163444,303.995545 432,296.832101 432,287.995545 C432,279.158989 439.163444,271.995545 448,271.995545 C456.836556,271.995545 464,279.158989 464,287.995545 C464,296.832101 456.836556,303.995545 448,303.995545 Z M0,495.995545 C0,504.832101 7.163444,511.995545 16,511.995545 L496,511.995545 C504.836556,511.995545 512,504.832101 512,495.995545 L512,383.995545 L0,383.995545 L0,495.995545 Z M448,431.995545 C456.836556,431.995545 464,439.158989 464,447.995545 C464,456.832101 456.836556,463.995545 448,463.995545 C439.163444,463.995545 432,456.832101 432,447.995545 C432,439.158989 439.163444,431.995545 448,431.995545 Z M64,431.995545 C72.836556,431.995545 80,439.158989 80,447.995545 C80,456.832101 72.836556,463.995545 64,463.995545 C55.163444,463.995545 48,456.832101 48,447.995545 C48,439.158989 55.163444,431.995545 64,431.995545 Z" />
              </g>
            </svg>
          </div>
        )}
         {price && product.hasActivePromo && (
          <div className="flex flex-row items-center">
          <div 
            style={{ textDecoration: 'line-through' }}
            className="text-center text-xs font-extrabold text-gray-500"
          >
            ${product.regularPrice?.toFixed(2)}
          </div> 
          <div className="text-center ml-2 text-md font-extrabold text-red-700">
            ${price?.toFixed(2)}
          </div>                      
          </div>
        )}
         {price && !product.hasActivePromo && (         
          <div className="text-center text-sm text-bottom font-extrabold">
            ${price?.toFixed(2)}
          </div>         
        )}
        <div className={brand ? `w-full text-center font-medium text-gray-400 ${small ? 'leading-3 text-xxs' : 'line-clamp-1 text-xs'}` : 'hidden'}>
          {brand}
        </div>
        <div className={name ? `w-full text-center font-sm capitalize overflow-ellipsis ${small ? 'leading-3 text-xxs' : 'line-clamp-1 text-xs'}` : 'hidden'}>
          {_.toLower(name)}
        </div>
        <div className={description ? `w-full text-center text-gray-500 lowercase overflow-ellipsis ${small ? 'leading-3 text-xxs' : 'line-clamp-1 text-xs'}` : 'hidden'}>
          {description}
        </div>
        <ItemTimeConstraintWarning product={product} />
        <Counter product={product} />
      </div>
    </div>
  );
}

export default Card;
