import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import {
  envAtom,
  notificationsAtom,
  tokenAtom,
  stateAtom,
  userAtom,
  isLoggingInAtom,
} from '../../../../atoms/Atoms';
import Form from '../../../shared-components/form/Form';
import Input from '../../../shared-components/form/Input';
import CleaveInput from '../../../shared-components/form/CleaveInput';
import Select from '../../../shared-components/form/Select';
import Button from '../../../shared-components/buttons/Button';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';

function RegisterForm({ referral }) {
  const { i18n, t } = useTranslation();
  const env = useRecoilValue(envAtom);
  const history = useHistory();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const setIsLoggingIn = useSetRecoilState(isLoggingInAtom);
  const setUser = useSetRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setState = useSetRecoilState(stateAtom);

  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      gender: 5,
      language: 5,
      password: '',
      passwordConfirmation: '',
      friendCode: referral,
      allowMarketing: true,
      acceptTos: false
    },
    validationSchema: Yup.object().shape({
      fname: Yup.string().required(`${t('required_field')}`),
      lname: Yup.string().required(`${t('required_field')}`),
      email: Yup.string()
        .email(`${t('valid_email_validation')}`)
        .required(`${t('required_field')}`),
      phone: Yup.string().required(`${t('required_field')}`),
      password: Yup.string().required(`${t('required_field')}`),
      passwordConfirmation: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          `${t('same_password')}`
        )
        .required(`${t('required_field')}`),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setIsLoggingIn(true);
      const payload = {
        Email: values.email,
        FirstName: values.fname,
        LastName: values.lname,
        PhoneNumber: values.phone,
        Password: values.password,
        Birthdate: new Date(),
        Gender: parseInt(values.gender, 10),
        PreferredLanguage: parseInt(values.language, 10),
        FriendCode: values.friendCode,
        AllowMarketing: values.allowMarketing
      };
      // console.log(payload);
      api.post('user/register', payload)
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description:
                `${t('registration_error')}`,
                error: true,
              },
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: `${t('yay')}`,
                description:
                `${t('registration_success')}`,
                error: false,
              },
            ]);
            localStorage.setItem(
              `${envConfig.StorageKey}-state`,
              JSON.stringify(response.data.data.state)
            );
            localStorage.setItem(
              `${envConfig.StorageKey}-user`,
              JSON.stringify(response.data.data.state.profile)
            );
            localStorage.setItem(
              `${envConfig.StorageKey}-token`,
              JSON.stringify(response.data.data.accessToken)
            );
            formik.resetForm();
            setState(response.data.data.state);
            setUser(response.data.data.state.profile);
            setToken(response.data.data.accessToken);
            history.push('/');
          }
          setSubmitting(false);
          setIsLoggingIn(false);
        })
        .catch((error) => {
          setNotifications({ ...notifications, signUpError: error.message });
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: `${t('registration_error')} - ${error.message}`,
              error: true,
            },
          ]);
          setSubmitting(false);
          setIsLoggingIn(false);
        });
    },
  });

  return (
    <>
      <Form>
        <Input
          label={t('name')}
          required
          type="text"
          name="fname"
          id="fname"
          placeholder={t('sample_first_name')}
          autoComplete="given-name"
          autoCapitalize="true"
          value={formik.values.fname}
          onChange={formik.handleChange}
          error={
            formik.errors.fname && formik.touched.fname && formik.errors.fname
          }
        />
        <Input
          label={t('lastname')}
          required
          type="text"
          name="lname"
          id="lname"
          autoComplete="family-name"
          placeholder={t('sample_last_name')}
          autoCapitalize="true"
          value={formik.values.lname}
          onChange={formik.handleChange}
          error={
            formik.errors.lname && formik.touched.lname && formik.errors.lname
          }
        />
        <Input
          label={t('email')}
          required
          type="email"
          name="email"
          id="email"
          placeholder={t('sample_email')}
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={
            formik.errors.email && formik.touched.email && formik.errors.email
          }
        />
        <CleaveInput
          label={t('phone')}
          required
          id="phone"
          name="phone"
          type="text"
          autoComplete="tel"
          options={{
            phone: true,
            phoneRegionCode: 'PR',
          }}
          value={formik.values.phone}
          onChange={(e) => {
            formik.setFieldValue('phone', e.target.rawValue);
          }}
          placeholder="787 777 7777"
          error={
            formik.errors.phone && formik.touched.phone && formik.errors.phone
          }
        />
        <Select
          className="text-xs"
          label={t('gender')}
          id="gender"
          name="gender"
          value={formik.values.gender}
          onChange={formik.handleChange}
          options={[
            // {
            //   key: '5',
            //   value: 5,
            //   text: `${t('select_gender')}`,
            //   disabled: true,
            // },
            {
              key: '2',
              value: 2,
              text: `${t('unkown_gender')}`,
            },
            {
              key: '0',
              value: 0,
              text: `${t('male')}`,
            },
            {
              key: '1',
              value: 1,
              text: `${t('female')}`,
            },
          ]}
        />
        <Select
          className="text-xs"
          label={t('language')}
          id="language"
          name="language"
          value={formik.values.language}
          onChange={formik.handleChange}
          options={[
            {
              key: '0',
              value: 0,
              text: `${t('english')}`,
            },
            {
              key: '1',
              value: 1,
              text: `${t('spanish')}`,
            },
          ]}
        />
        <Input
          label={t('password')}
          required
          id="password"
          name="password"
          type="password"
          autoComplete="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="***"
          error={
            formik.errors.password &&
            formik.touched.password &&
            formik.errors.password
          }
        />
        <Input
          label={t('confirm_password')}
          required
          id="passwordConfirmation"
          name="passwordConfirmation"
          type="password"
          autoComplete="password"
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange}
          placeholder="***"
          error={
            formik.errors.passwordConfirmation &&
            formik.touched.passwordConfirmation &&
            formik.errors.passwordConfirmation
          }
        />
        <Input
          label={t('referral_code')}
          type="text"
          name="friendCode"
          id="friendCode"
          disabled={referral}
          placeholder="xxxx-xxxx"
          value={formik.values.friendCode}
          onChange={formik.handleChange}
        />
      </Form>
      <div className="flex items-center">
        <input
          value={formik.values.allowMarketing}
          checked={formik.values.allowMarketing}
          name="allowMarketing"
          type="checkbox"
          onChange={formik.handleChange}
          className="w-4 h-4 text-accent bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label htmlFor="marketing-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {t('check_to_accept_marketing')}
        </label>
      </div>
      <div className="flex items-center mt-2">
        <input
          value={formik.values.acceptTos}
          name="acceptTos"
          type="checkbox"
          onChange={formik.handleChange}
          className="w-4 h-4 text-accent bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label htmlFor="marketing-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {t('check_to_accept_tos')}: <a href={envConfig.TosUrl} target="_blank" rel="noreferrer" className="text-accent hover:underline text-sm font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">{t('view_here')}</a>
        </label>
      </div>
      <Button
        text={t('cancel')}
        textColor="text-gray-400"
        onClick={(e) => {
          e.preventDefault();
          history.goBack();
        }}
        className="bg-gray-200 hover:bg-gray-300 w-20 mr-2 mt-4"
      />
      <Button
        text={t('register')}
        textColor="text-white"
        disabled={formik.isSubmitting || !formik.values.acceptTos}
        loading={formik.isSubmitting}
        onClick={formik.submitForm}
        loaderColor="bg-white"
        className="bg-accent hover:bg-accent w-20"
      />
    </>
  );
}

export default RegisterForm;
