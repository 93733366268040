import React from 'react';
import envConfig from '../../../envConfig';
import Subheader1 from './shared-components/Subheader1';
import Subheader2 from './shared-components/Subheader2';

function Subheader() {
  if (envConfig.SubHeaderVersion === '1') { 
    return ( 
      <Subheader1 />
  )
  } if (envConfig.SubHeaderVersion === '2') {
    return ( 
      <Subheader2 />
  )
  }
  if (envConfig.SubHeaderVersion === '3') {
    return ( 
      <Subheader1 />
  )
  }
  return ( 
    <Subheader1 />
  )
}

export default Subheader;
