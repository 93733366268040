import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LoginForm from './shared-components/LoginForm';
import { H1 } from '../../shared-components/typography/Title';
import { userAtom, isLoggingInAtom } from '../../../atoms/Atoms';


function Login() {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useRecoilValue(userAtom);
  const isLoggingIn = useRecoilValue(isLoggingInAtom);

  useEffect(() => {
    if (user && !isLoggingIn) {
      history.push('/');
    }
  }, [user, isLoggingIn]);

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="flex flex-col justify-center py-6 px-4 md:px-0">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <H1 text={t('signin')} />
          <div className="text-xs tracking-tight space-x-1">
            <span>{t('not_regsitered_yet')}</span>
            <Link
              to="/register"
              className="text-accent hover:underline transform duration-500 ease-in-out"
            >
              {t('register_here')}
            </Link>
          </div>
          <LoginForm />
          <div className="text-xs tracking-tight mt-4 space-x-1">
            <span>{t('forgot_password')}</span>
            <Link
              to="/password"
              className="text-accent hover:underline transform duration-500 ease-in-out"
            >
              {t('recover_password')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
