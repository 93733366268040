import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useUnmount, useMount } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Carousel from './carousel/Carousel';
import Services from './services/Services';
import StoreDialog from './StoreDialog';
import { locationAtom, popupAtom, storesDialogAtom } from '../../../../atoms/Atoms';
import FeedbackFooter from '../../../shared-components/feedback-footer/FeedbackFooter';
import BannerCarousel from '../../../shared-components/banner-carousel/BannerCarousel';
import Register from '../../../shared-components/locations/shared-components/register/Register';
import ShopperButton from './ShopperButton';

function Home3() {
  const { t } = useTranslation();
  const setDialog = useSetRecoilState(storesDialogAtom);
  const [registerLocation, setRegisterLocation] = useRecoilState(locationAtom);
  const [showPopup, setShowPopup] = useRecoilState(popupAtom);

  useUnmount(() => {
    setDialog(false);
  });

  useMount(() => {
    setShowPopup(true);
  });

  return (
  <div className="flex-1 overflow-y-auto bg-white">
  <motion.div
    initial={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    animate={{
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    exit={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
  >
    <StoreDialog />
    {registerLocation.current === 'register-location' && <Register />}
    <div className="flex flex-col px-2 sm:px-12 md:px-24 lg:px-52">
      <BannerCarousel />
      <StoreDialog />
      <ShopperButton />
      <Services />     
      <Carousel />       
    </div>
    <FeedbackFooter />
  </motion.div>
  </div>)
}

export default Home3;
