import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { motion } from 'framer-motion';
import { checkoutSelector } from '../../../../atoms/Selectors';
import { itemInstructionsModalAtom } from '../../../../atoms/Atoms';
import Counter from '../../../shared-components/Counter';

function ExcludeOrder() {
  const checkout = useRecoilValue(checkoutSelector);
  const setItemInstructions = useSetRecoilState(itemInstructionsModalAtom);
  const { t } = useTranslation();

  const getTotal = (id) => {
    const filter = _.filter(checkout.excludedItems, (e) => e.id === id);
    const price = filter[0].activePrice;
    let amount = 0;
    for (let i = 0; i < filter.length; i++) {
      amount += filter[i].amount;
    }
    return {
      subtotal: price,
      units: amount,
      total: (price * amount).toFixed(2),
    };
  };

  const containerVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <>
      <motion.div
        initial={false}
        animate={checkout.excludedItems.length > 0 ? 'open' : 'closed'}
      >
        <motion.div variants={containerVariants} className="mt-4 space-y-2">
          {_.map(
            _.orderBy(_.uniqBy(checkout.excludedItems, 'id'), 'name'),
            (e, index) => (
              <motion.div
                variants={variants}
                key={index}
                className="flex space-x-4"
              >
                <button
                  type="button"
                  onClick={() => setItemInstructions({
                    open: true,
                    itemId: e.id,
                    item: e
                  })}
                  className="hover:opacity-75 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faEdit} className="text-md" />
                </button>
                <div className="flex flex-shrink-0 flex-col items-center justify-center rounded-xl bg-white border space-y-2 p-2">
                  <img alt={e.name} src={e.imageUrl} className="h-16 w-auto" />
                  <Counter product={e} />
                </div>
                <div className="flex flex-col flex-1 text-xs truncate">
                  <div className="w-full truncate font-bold tracking-tight capitalize">
                    {_.toLower(e.name)}
                  </div>
                  <div className={`w-full truncate font-semibold tracking-tight text-xs ${e.hasActivePromo ? 'text-red-700' : ''}`}>
                    ${getTotal(e.id).subtotal} x {getTotal(e.id).units}{' '}
                    {e.hasRandomWeight &&
                      (getTotal(e.id).units === 1 ? t('pound') : t('pounds'))}
                    {!e.hasRandomWeight &&
                      (getTotal(e.id).units === 1 ? t('unit') : t('units'))}{' '}
                    = ${getTotal(e.id).total}
                  </div>
                  <div className="w-full truncate text-gray-500 font-light tracking-tight text-xs">
                    {e.brand}
                  </div>
                  <div className="w-full truncate text-gray-500 font-light tracking-tight capitalize text-xs">
                    {_.toLower(e.description)}
                  </div>
                  <div className="w-full truncate text-gray-600 italic font-light tracking-tight text-xs">
                    {e.specialInstruction}
                  </div>
                </div>
              </motion.div>
            )
          )}
        </motion.div>
      </motion.div>
      {checkout.excludedItems.length === 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 1,
            delay: 0.5,
          }}
          className="h-full flex flex-col flex-1 items-center justify-center"
        >
          <div className="text-gray-400 text-xs text-center">
            {t('item_excluded')}
          </div>
        </motion.div>
      )}
    </>
  );
}

export default ExcludeOrder;
