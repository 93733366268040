import React, { useEffect } from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { departmentsAtom, storeAtom, catalogAtom, envAtom } from '../../../../atoms/Atoms';
import Breadcrumb from './Breadcrumb';
import Carousel from './carousel/Carousel';
import Loader from '../../../shared-components/loader/Loader';
import Banner from '../../../shared-components/banners/Banner';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';

function Store1() {
  const { id } = useParams();
  const [departments, setDepartments] = useRecoilState(departmentsAtom);
  const [catalog, setCatalog] = useRecoilState(catalogAtom);
  const store = useRecoilValue(storeAtom);

  useEffect(() => {
    api({
      method: 'GET',
      url: `catalog/${id}`,
    })
      .then((response) => {
        setCatalog(response.data.data);
        setDepartments(response.data.data.departments);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  if (envConfig.TemplateVersion === '0') {
  return (
    <div className="flex-1 overflow-y-auto">
      <motion.div
        initial={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        exit={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
      >
       
        <Banner title={store && store.name} image="bg-carts" />
        <Breadcrumb />
        <div className="space-y-10">
          {!departments && (
            <div className="flex items-center justify-center mb-5">
              <Loader color="bg-gray-500" />
            </div>
          )}
          {departments &&
            <>
              {catalog?.sponsored && <Carousel key="sponsored" dynamicDepartment="sponsored" />}
              {catalog?.myPantry && <Carousel key="myPantry" dynamicDepartment="myPantry" />}
              {catalog?.offers && <Carousel key="offers" dynamicDepartment="offers" />}
              {catalog?.wishlist && <Carousel key="wishlist" dynamicDepartment="wishlist" />}
              {catalog?.favorites && <Carousel key="favorites" dynamicDepartment="favorites" />}
              {catalog?.madeLocally && <Carousel key="madeLocally" dynamicDepartment="madeLocally" />}
              {catalog?.recentlyAdded && <Carousel key="recentlyAdded" dynamicDepartment="recentlyAdded" />}
              {catalog?.customDepartment01 && <Carousel key="customDepartment01" dynamicDepartment="customDepartment01" />}
              {catalog?.customDepartment02 && <Carousel key="customDepartment02" dynamicDepartment="customDepartment02" />}
              {catalog?.customDepartment03 && <Carousel key="customDepartment03" dynamicDepartment="customDepartment03" />}
              {catalog?.customDepartment04 && <Carousel key="customDepartment04" dynamicDepartment="customDepartment04" />}
              {catalog?.customDepartment05 && <Carousel key="customDepartment05" dynamicDepartment="customDepartment05" />}
              {catalog?.customDepartment06 && <Carousel key="customDepartment06" dynamicDepartment="customDepartment06" />}
              {_.map(departments, (e) => <Carousel key={e.id} department={e} />)}
            </>}
        </div>
      </motion.div>
    </div>
  ) 
} 
    return (
      <div className="flex-1 overflow-y-auto bg-white content-center px-2 sm:px-12 md:px-24 lg:px-52">
        <motion.div
          initial={{
            y: 100,
            opacity: 0,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
          exit={{
            y: 100,
            opacity: 0,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
        >
          {/* <Breadcrumb /> */}
          <div className="space-y-1 pt-4">
            {!departments && (
              <div className="flex items-center justify-center mb-5">
                <Loader color="bg-gray-500" />
              </div>
            )}
            {departments &&
              <>
                {catalog?.sponsored && <Carousel key="sponsored" dynamicDepartment="sponsored" />}
                {catalog?.myPantry && <Carousel key="myPantry" dynamicDepartment="myPantry" />}
                {catalog?.offers && <Carousel key="offers" dynamicDepartment="offers" />}
                {catalog?.wishlist && <Carousel key="wishlist" dynamicDepartment="wishlist" />}
                {catalog?.favorites && <Carousel key="favorites" dynamicDepartment="favorites" />}
                {catalog?.madeLocally && <Carousel key="madeLocally" dynamicDepartment="madeLocally" />}
                {catalog?.recentlyAdded && <Carousel key="recentlyAdded" dynamicDepartment="recentlyAdded" />}
                {catalog?.customDepartment01 && <Carousel key="customDepartment01" dynamicDepartment="customDepartment01" />}
                {catalog?.customDepartment02 && <Carousel key="customDepartment02" dynamicDepartment="customDepartment02" />}
                {catalog?.customDepartment03 && <Carousel key="customDepartment03" dynamicDepartment="customDepartment03" />}
                {catalog?.customDepartment04 && <Carousel key="customDepartment04" dynamicDepartment="customDepartment04" />}
                {catalog?.customDepartment05 && <Carousel key="customDepartment05" dynamicDepartment="customDepartment05" />}
                {catalog?.customDepartment06 && <Carousel key="customDepartment06" dynamicDepartment="customDepartment06" />}
                {_.map(departments, (e) => <Carousel key={e.id} department={e} />)}
              </>}
          </div>
        </motion.div>
      </div>
    )
  }

export default Store1;
