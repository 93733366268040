import React, { useEffect } from 'react';
import envConfig from '../../../envConfig';
import ServiceSelection1 from './shared-components/ServiceSelection1';
import ServiceSelection2 from './shared-components/ServiceSelection2';


function ServiceSelection() {
  if (envConfig.HomeVersion === '1') {
    return (
      <ServiceSelection1 />
    )
  } 
  if (envConfig.HomeVersion === '2') {
    return (
      <ServiceSelection2 />
    )
  } 
  if (envConfig.HomeVersion === '3') {
    return (
      <ServiceSelection1 />
    )
  } 
  return (
    <ServiceSelection1 />
  )
} 

export default ServiceSelection;
