import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _, { forEach } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DatePicker } from 'antd';
import moment from 'moment/moment';
import { AnimatePresence, motion } from 'framer-motion';
import api from '../../../../api/api';
import {
  dayAtom,
  envAtom,
  locationAtom,
  serviceAtom,
  storeAtom,
  timeAtom,
  timeSlotAtom,
  tokenAtom,
} from '../../../../atoms/Atoms';
import Panel from '../../../shared-components/panel/Panel';
import Loader from '../../../shared-components/loader/Loader';
import { H3 } from '../../../shared-components/typography/Title';
import Subtitle from '../../../shared-components/typography/Subtitle';
import Form from '../../../shared-components/form/Form';
import Select from '../../../shared-components/form/Select';

require('dayjs/locale/es');

dayjs.locale('es');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function Hours() {
  const token = useRecoilValue(tokenAtom);
  const env = useRecoilValue(envAtom);
  const store = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);
  const [days, setDays] = useState([]);
  const [times, setTimes] = useState([]);
  const [day, setDay] = useRecoilState(dayAtom);
  const setTime = useSetRecoilState(timeAtom);
  const setTimeSlot = useSetRecoilState(timeSlotAtom);
  const location = useRecoilValue(locationAtom);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (store && token) {
      setLoading(true);
      setDay(null);
      setTime(null);
      setTimeSlot(null);
      api
        .post(
          `business/hours/${store?.locations[0]?.id}`,
          {
            OrderTotal: 0,
            ItemCount: 0,
            OrderType: service,
            DeliveryLocationId: service === 1 ? location?.location?.id : null,
          }
        )
        .then((response) => {
          setDays(response.data.data.results);
          // setTimes(response.data.data.results[0].times);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [token, store, service, location.location]);

  useEffect(() => {
    if (day) {
      const singleTime = days[0].times[0];
      console.log('single time', singleTime);
      const tempTimes = [];
      for (let i = 14; i <= 15; i++) {
        tempTimes.push({
          ...singleTime,
          isAvailable: true,
          time: dayjs(day).startOf('day').add(i, 'hour').utc()
          .format(),
          endTime: dayjs(day).startOf('day').add(i + 1, 'hour').utc()
          .format()
        });
      }
      setTimes(tempTimes);
    }
  }, [day]);

  return (
    <Panel>
      <H3 text={t('date')} />
      <Subtitle
        text={t('pickup_hour')}
      />
      <div className="flex items-center justify-center">
        {days.length === 0 && !loading && (
          <div
            style={{ height: 78 }}
            className="flex items-center justify-center text-base"
          >
            {t('no_available_hours')}
          </div>
        )}
          {days.length === 0 && loading && (
          <div
            style={{ height: 78 }}
            className="flex items-center justify-center"
          >
            <Loader />
          </div>
        )}
        <AnimatePresence>
          {days.length > 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0 }}
              className="w-full "
            >
              <Form>
                <DatePicker
                  format="YYYY-MM-DD"
                  highlighted
                  disabledDate={(d) => !d || d.isBefore(dayjs())}
                  onChange={(e, dateString) => {
                      setDay(moment(dateString));
                    }}
                />  
                <Select
                  disabled={!day}
                  highlighted
                  onChange={(e) => {
                    const selectedTime = dayjs(e.target.value).utc().format()
                    setTime(e.target.value);
                    const filtered = _.filter(times, (ti) => ti.time === selectedTime);
                    setTimeSlot(filtered[0]);
                  }}
                  options={[
                    {
                      key: 'placeholder',
                      value: null,
                      text: t('select_time_placeholder'),
                    },
                    ..._.map(times, (x, index) => ({
                      key: `hour-${index}`,
                      value: dayjs(x.time).utc().format(),
                      disabled: !x.isAvailable,
                      text: dayjs(x.endTime).tz(env?.timezone).format('h:mm A'),
                    })),
                  ]}
                />
              </Form>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Panel>
  );
}

export default Hours;
