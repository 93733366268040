import React, { useEffect, useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { itemInstructionsModalAtom, orderAtom } from '../../../atoms/Atoms';
import { backdropVariants } from '../../../utils';
import Input from '../form/Input';
import Button from '../buttons/Button';

function ItemInstructionsModal() {
  const { t } = useTranslation();
  const ref = useRef();
  const [itemInstructionsModal, setItemInstructionsModal] =
    useRecoilState(itemInstructionsModalAtom);
  const [specialInstruction, setSpecialInstruction] = useState('');
  const [order, setOrder] = useRecoilState(orderAtom);

  useEffect(() => {
    if (itemInstructionsModal.open) {
      setSpecialInstruction(itemInstructionsModal.item?.specialInstruction);
    } else {
      setSpecialInstruction('');
    }
  }, [itemInstructionsModal]);

  useClickAway(ref, () => {
    setItemInstructionsModal({
      open: false,
      itemId: null,
      item: null
    });
  });

  function submit() {
    const newOrder = JSON.parse(JSON.stringify(order));
    const index = _.findIndex(order, (o) => o.id === itemInstructionsModal.itemId);
    newOrder[index].specialInstruction = specialInstruction;
    setOrder(newOrder);
    setItemInstructionsModal({
      open: false,
      itemId: null,
      item: null
    });
  }

  return (
    <AnimatePresence>
      {itemInstructionsModal.open && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{ zIndex: 1001 }}
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-full md:px-32 xl:w-2/3 px-4">
              <div
                ref={ref}
                className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
              >
                <div className="flex items-center justify-end">
                  <button
                    type="button"
                    onClick={() => setItemInstructionsModal({
                        open: false,
                        itemId: null,
                        item: null
                      })}
                    className="hover:opacity-75 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
                <div className="flex space-x-4">
                  <div className="font-light tracking-tight text-md md:text-lg">
                    {`${t('instructions_for')}: ${itemInstructionsModal.item?.completeName}`}
                  </div>
                </div>
                <Input
                  type="text"
                  name="instructions"
                  id="instructions"
                  placeholder={t('sample_instructions')}
                  autoCapitalize="true"
                  value={specialInstruction}
                  onChange={(e) => { setSpecialInstruction(e.target.value); }}
                />
                <div className="flex items-center justify-end mt-4 space-x-2">
                  <Button
                    text={t('cancel')}
                    onClick={() => {
                      setItemInstructionsModal({
                        open: false,
                        itemId: null,
                        item: null
                      });
                    }}
                    className="h-10 w-full md:w-20 text-gray-400 bg-gray-200 hover:bg-gray-300"
                  />
                  <Button
                    text={t('submit')}
                    onClick={() => submit()}
                    className="h-10 w-full md:w-20 bg-green-500 text-white hover:bg-accent"
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default ItemInstructionsModal;
