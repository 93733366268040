import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useUnmount, useMount } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Banner from './banner/Banner';
import Carousel from './carousel/Carousel';
import Services from './services/Services';
import StoreDialog from './StoreDialog';
import { locationAtom, popupAtom, storesDialogAtom } from '../../../../atoms/Atoms';
import DynamicBanner from '../../../shared-components/dynamic-banner/DynamicBanner';
import FeedbackFooter from '../../../shared-components/feedback-footer/FeedbackFooter';
import ShopperButton from './ShopperButton';

function Home1() {
  const { t } = useTranslation();
  const setDialog = useSetRecoilState(storesDialogAtom);
  const [registerLocation, setRegisterLocation] = useRecoilState(locationAtom);
  const [showPopup, setShowPopup] = useRecoilState(popupAtom);

  useUnmount(() => {
    setDialog(false);
  });

  useMount(() => {
    setShowPopup(true);
  });

   return (
    <div className="flex-1 overflow-y-auto">
      <motion.div
        initial={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        exit={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
      >
        <StoreDialog />
        <DynamicBanner />
        <ShopperButton />
        <Banner />
        <Carousel />
        <Services />        
        <FeedbackFooter />
      </motion.div>
    </div>
  )
}

export default Home1;
