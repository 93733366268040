import React from 'react';
import _ from 'lodash';
import { Menu } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusAlt, faSearch, faShoppingBag } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { ShoppingBagIcon, SearchIcon } from '@heroicons/react/outline';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  locationAtom,
  notificationsAtom,
  searchBrandAtom,
  searchPageAtom,
  searchStringAtom,
  serviceAtom,
  storeAtom,
  serviceSelectionAtom,
  userAtom,
  departmentDrawerAtom,
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import { phoneNumber } from '../../../../utils';
import envConfig from '../../../../envConfig';
import ServicesDrawer from '../../service-selection/shared-components/ServicesDrawer';
import CustomDepartmentsControl from '../../custom-depatments-control/CustomDepartmentsControl';
import DepartmentsDrawer from '../../departments-drawer/DepartmentsDrawer';

function Subheader2() {
  const [user, setUser] = useRecoilState(userAtom);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const setFilter = useSetRecoilState(searchBrandAtom);
  const store = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [searchString, setSearchString] = useRecoilState(searchStringAtom);
  const [search, setSearch] = useRecoilState(searchPageAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [serviceSelection, setServiceSelection] = useRecoilState(serviceSelectionAtom);
  const [openDepts, setDeptsOpen] = useRecoilState(departmentDrawerAtom);

  const menuItems = [
    {
      id: '1',
      to: '/',      
      name: 'home',
      text: t('home'),
      hidden: true,
      style: 'link',
    },
    {
      id: '3',
      name: 'departments',
      to: `/stores/${store && store.id}/departments`,      
      text: t('departments'),
      hidden: !store,
      style: `${envConfig.DepartmentsStyle}`,
    },
  ];

  const getProducts = () => {
    if (location.pathname !== `/search/${store.id}`) {
      history.push(`/search/${store.id}`);
    }

    setFilter('');

    setSearch({
      loading: true,
      products: [],
      brands: [],
      page: 0,
    });

    api
      .post(`catalog/search/${store.id}/0/${envConfig.SearchItemsPageSize}`, {
        SearchType: 0,
        searchString,
      })
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: t('oops'),
              description:
                t('search_error'),
              error: true,
            },
          ]);
          setSearch({
            loading: false,
            products: [],
            brands: [],
            page: 0,
          });
        } else {
          const brands = _.map(
            _.uniqBy(response.data.data.items, 'brand'),
            (item) => item.brand
          );
          setSearch({
            ...search,
            loading: false,
            products: response.data.data.items,
            brands,
            page: 0,
            loadMore: response.data.data.items.length === envConfig.SearchItemsPageSize
          });
        }
      })
      .catch((error) => {
        setSearch({
          loading: false,
          products: [],
          brands: [],
          page: 0,
        });
        setNotifications([
          ...notifications,
          {
            title: t('oops'),
            description:
              t('search_error'),
            error: true,
          },
        ]);
      });
  };

  if (store && service !== -1) {
    return (
  <div className="flex flex-row items-center space-x-2 p-2 bg-headerBackground h-14" style={{ backgroundColor: 'white' }}>
    <div className="flex flex-row text-sm font-light ml-0 md:ml-4">
      <div className="text-black">
        <FontAwesomeIcon icon={faShoppingBag} className="text-lg mr-2" />
      </div>
    <div className="text-black ml-1 hidden md:block">
      {store && store.name}
    </div>
    </div>
    <nav className="hidden md:flex flex-1 items-center space-x-3">
      {_.map(menuItems, (e) => {      
        if (e.style === 'sidebar-right' && e.name === 'departments') {
          return (
          <button
            type="button"
            key={e.id}
            className="flex items-center text-black hover:text-gray-200 text-sm font-light tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0"
            onClick={() => {           
            setDeptsOpen(!openDepts); 
          }}
          >
          <div className="truncate hover:underline">{e.text}</div>
          </button>)
        } 
       if (e.style !== 'button') {
        return (
        <NavLink
          key={e.id}
          exact     
          activeClassName="text-black underline"
          to={e.to}
          className="flex items-center text-black hover:text-gray-200 text-sm font-light tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0"
        >
          <div className="truncate" hidden={e.hidden}>{e.text}</div>
        </NavLink>)
      }     
      }) }
      {store && (
        <Menu>
          <div className="relative">
            <Menu.Button className="flex items-center space-x-2 text-black hover:text-gray-200 text-sm tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">
              <div className="truncate">{t('contact')}</div>
              {/* <FontAwesomeIcon icon={faChevronDown} /> */}
            </Menu.Button>
            <Menu.Items className="text-xs divide-y absolute z-50 transform -translate-x-1/2 left-1/2 mt-2 h-auto w-60 no-scrollbar overflow-auto bg-white divide-y divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none">
              <Menu.Item
                as="div"
                className="p-4 ring-0 outline-none focus:ring-0 focus:outline-none text-sm"
              >
                <div className="font-semibold">
                  {store.locations[0].name}
                </div>
                <div className="text-gray-500">
                  {store.locations[0].address1}.{' '}
                  {store.locations[0].address2}
                </div>
                <div className="text-gray-500">
                  {t('phone')}: {phoneNumber(store.locations[0].orderPhone)}
                </div>
                <div className="text-gray-500">
                  {t('email')}: {store.locations[0].orderEmail}
                </div>
              </Menu.Item>
              <Menu.Item
                as="a"
                href={`tel:${store.locations[0].orderPhone}`}
                className="flex items-center justify-center text-center font-semibold p-4 w-full text-left font-medium text-xs tracking-tight bg-accent text-black hover:bg-gray-700 outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 easy-in-out lg:hidden xl:hidden 2xl:hidden"
              >
                {t('call_now')}
              </Menu.Item>
            </Menu.Items>
          </div>
        </Menu>
      )}
    </nav>
    <ServicesDrawer />
    {store && (
      <div className="flex flex-1 md:flex-none border rounded-3xl bg-white">
        <input
          className="border-0 focus:ring-0 h-10 block w-full rounded-l-3xl text-gray-400 placeholder-gray-400 font-medium text-xs tracking-tight"
          type="search"
          name="search"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          value={searchString}
          onKeyDown={(event) => {
            if (searchString && event.key === 'Enter') getProducts();
          }}
          onChange={(event) => setSearchString(event.target.value)}
          placeholder={`${t('search_products')}...`}
        />
        <button
          type="button"
          onClick={() => {
            if (searchString) getProducts();
          }}
          className="text-white tracking-tight hover:text-gray-400 md:full border-l inline-flex items-center rounded-r-3xl rounded-l-3xl space-x-1 px-4 rounded-r-md bg-base hover:bg-opacity-75 focus:outline-none transition duration-500 ease-in-out"
        >
          <SearchIcon className="md:h-6 h-4" />   
        </button>
      </div>
    )}
  </div>) 
  }
    return null;
}

export default Subheader2;
