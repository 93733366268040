import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { NavLink } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { popupAtom } from '../../../atoms/Atoms';
import image from '../../../assets/img/placeholders/promo1.png';
import { backdropVariants, modalVariants } from '../../../utils';
import { bannerAdsListSelector, infoCardsSelector } from '../../../atoms/Selectors';

function Popup() {
  const ref = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const banners = useRecoilValueLoadable(bannerAdsListSelector);

  useClickAway(ref, () => {
    setShowPopup(false);
  });

  useEffect(() => {
    if (!banners) setShowPopup(false);
    if (banners && banners?.contents.data?.data?.popups.length > 0) setShowPopup(true);
  }, [banners]);

  return (
    <AnimatePresence>
      {showPopup && (
        <motion.div
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{ zIndex: 1001 }}
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-2"
        >
          <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative mx-auto">
              <div
                ref={ref}
                className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4"
              >
                <div className="flex items-center justify-end">
                  <button
                    type="button"
                    onClick={() => setShowPopup(false)}
                    className="hover:opacity-75 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
                <div className="flex space-x-4">
                  <div className="h-auto w-auto" style={{ position: 'relative', top: 0, left: 0 }}>
                    <img alt="Promo" className="mt-0" name="Promo" src={banners && banners?.contents.data?.data?.popups[0]?.imageUrl} /> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Popup;
