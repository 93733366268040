import React, { useEffect } from 'react';
import envConfig from '../../../envConfig';
import Departments1 from './shared-components/Departments1';
import Departments2 from './shared-components/Departments2';

function Departments() {
  if (envConfig.TemplateVersion === '1') {
  return (
    <Departments1 />
  ) 
}
if (envConfig.TemplateVersion === '2') {
  return (
    <Departments2 />
  ) 
}
return (
  <Departments1 />
) 
}

export default Departments;
