import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { showShopperAtom } from '../../../../atoms/Atoms';
import envConfig from '../../../../envConfig';

function ShopperButton() {
  const { t } = useTranslation();
  const setShowShopper = useSetRecoilState(showShopperAtom);
  if (envConfig.ShowShopperButton && envConfig.ShowShopperButton === 'true') {
    return (
        <div className="flex flex-col xl:flex-row items-center justify-center">
          <div 
            role="button"  
            tabIndex={-1}
            onClick={() => {
              setShowShopper(true);
            }}
            className="bg-base flex flex-row rounded-xl border items-center justify-center text-white p-4 m-4 shadow"
          >
            <div className="font-semibold text-md md:text-lg">{t('view_shopper')}</div>
          </div>
        </div>
      )
  }
  return <></>
}
export default ShopperButton;
