import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams, NavLink, useHistory } from 'react-router-dom';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { catalogAtom, departmentPageAtom, departmentsAtom, storeAtom } from '../../../atoms/Atoms';


function CustomDepartmentsControl() {
  const departments = useRecoilValue(departmentsAtom);
  const [catalog, setCatalog] = useRecoilState(catalogAtom);
  const store = useRecoilValue(storeAtom);
  const { i18n, t } = useTranslation();
  const [selectedSearchType, setSelectedSearchType] = useState(null);
  const dynamicDepartment = useState(null);
  const history = useHistory();
  const [departmentState, setDepartmentState] = useRecoilState(
    departmentPageAtom
  );


  function dynamicDepartmentName() {
    let name = '';
    if (selectedSearchType === 6) {
      name = t('sponsored');
    } else if (selectedSearchType === 7) {
      name = t('buy_again');
    } else if (selectedSearchType === 9) {
      name = t('recently_added');
    } else if (selectedSearchType === 10) {
      name = t('made_locally');
    } else if (selectedSearchType === 5) {
      name = t('offers');
    } else if (selectedSearchType === 4) {
      name = t('wishlist');
    } else if (selectedSearchType === 3) {
      name = t('favorites');
    } else if (selectedSearchType === 12) {
      name = catalog?.customDepartment01Name;
    } else if (selectedSearchType === 13) {
      name = catalog?.customDepartment02Name;
    } else if (selectedSearchType === 14) {
      name = catalog?.customDepartment03Name;
    } else if (selectedSearchType === 30) {
      name = catalog?.customDepartment04Name;
    } else if (selectedSearchType === 31) {
      name = catalog?.customDepartment05Name;
    } else if (selectedSearchType === 32) {
      name = catalog?.customDepartment06Name;
    }
    return name;
  }

  const dynamicDepartments = [
    { shouldShow: catalog?.sponsored, name: t('sponsored'), type: 6 },
    { shouldShow: catalog?.myPantry, name: t('buy_again'), type: 7 },
    { shouldShow: catalog?.offers, name: t('offers'), type: 5 },
    { shouldShow: catalog?.wishlist, name: t('wishlist'), type: 4 },
    { shouldShow: catalog?.favorites, name: t('favorites'), type: 3 },
    { shouldShow: catalog?.madeLocally, name: t('made_locally'), type: 10 },
    { shouldShow: catalog?.recentlyAdded, name: t('recently_added'), type: 9 },
    { shouldShow: catalog?.customDepartment01, name: catalog?.customDepartment01Name, type: 12 },
    { shouldShow: catalog?.customDepartment02, name: catalog?.customDepartment02Name, type: 13 },
    { shouldShow: catalog?.customDepartment03, name: catalog?.customDepartment03Name, type: 14 },
    { shouldShow: catalog?.customDepartment04, name: catalog?.customDepartment04Name, type: 30 },
    { shouldShow: catalog?.customDepartment05, name: catalog?.customDepartment05Name, type: 31 },
    { shouldShow: catalog?.customDepartment06, name: catalog?.customDepartment06Name, type: 32 },
  ]

  function dynamicDepartmentInfo(dept) {
    let name = '';
    let type = 0;
    if (dept === 'sponsored') {
      name = t('sponsored');
      type = 6;
    } else if (dept === 'myPantry') {
      name = t('buy_again');
      type = 7;
    } else if (dept === 'recentlyAdded' || 'Agregados Recientemente' || 'Recently Added') {
      name = t('recently_added');
      type = 9;
    } else if (dept === 'madeLocally') {
      name = t('made_locally');
      type = 10;
    } else if (dept === 'offers') {
      name = t('offers');
      type = 5;
    } else if (dept === 'wishlist') {
      name = t('wishlist');
      type = 4;
    } else if (dept === 'favorites') {
      name = t('favorites');
      type = 3;
    } else if (dept === 'customDepartment01') {
      name = catalog?.customDepartment01Name;
      type = 12;
    } else if (dept === 'customDepartment02') {
      name = catalog?.customDepartment02Name;
      type = 13;
    } else if (dept === 'customDepartment03') {
      name = catalog?.customDepartment03Name;
      type = 14;
    } else if (dept === 'customDepartment04') {
      name = catalog?.customDepartment04Name;
      type = 30;
    } else if (dept === 'customDepartment05') {
      name = catalog?.customDepartment05Name;
      type = 31;
    } else if (dept === 'customDepartment06') {
      name = catalog?.customDepartment06Name;
      type = 32;
    }
    return {
      title: name,
      apiUrl: `catalog/search/${store.id}`,
      storeUrl: `/stores/${store.id}/customdepartments/${type}`,
      searchType: type
    };
  }

  let apiUrl = '';
  if (catalog) {
        apiUrl = dynamicDepartmentInfo(dynamicDepartment).apiUrl;
  }



  return (
    <AnimateSharedLayout>
    <div className="hidden md:flex items-center justify-start p-4">
        {_.map(_.filter(dynamicDepartments, (d) => d.shouldShow), (d, index) => (
        <button
          type="button"
          onClick={() => {
            history.push(`/stores/${store.id}/customdepartments/${d.type}`);
            setDepartmentState({
                ...departmentState,
                searchType: dynamicDepartmentName(d.name).type,
            });
          }}
          key={index}
          className="text-xs overflow-x-auto md:text-sm bg-white rounded rounded-2xl px-4 py-0.5 relative text-accent hover:underline capitalize font-semibold ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out"
        >
        <div>{_.toLower(d.name)}</div>
        </button>
        ))}
    </div>
    </AnimateSharedLayout>
  );
}

export default CustomDepartmentsControl;
