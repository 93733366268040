import React from 'react';
import envConfig from '../../../envConfig';
import DynamicBanner1 from './shared-components/Dynbanner1';
import DynamicBanner2 from './shared-components/Dynbanner2';
import DynamicBanner3 from './shared-components/Dynbanner3';

function DynamicBanner() {
  if (envConfig.HomeVersion === '1') {
    return <DynamicBanner1 />
} if (envConfig.HomeVersion === '2') { 
  return <DynamicBanner2 />
} 
if (envConfig.HomeVersion === '3') { 
  return <DynamicBanner3 />
}
return <DynamicBanner1 />
}
export default DynamicBanner;
