import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import ItemsCarousel from 'react-items-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowToLeft,
  faArrowToRight,
} from '@fortawesome/pro-duotone-svg-icons';
import { storeAtom } from '../../../../atoms/Atoms';
import Card from '../../card/Card';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import Placeholder from './Placeholder';
import api from '../../../../api/api';

function Related({ product }) {
  const { t } = useTranslation();
  const store = useRecoilValue(storeAtom);
  const [products, setProducts] = useState([]);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const breakpoint = useBreakpoint();

  // console.log('bp', breakpoint);

  function sToScroll(bp) {
    if (bp === 7) {
      return 3; 
    }
    return 2;
  } 



  useEffect(() => {
    if (product) {
      api.get(`catalog/related/${store.id}/${product}`)
        .then(
          (response) => {
            if (response.data.data) {
              setProducts(response.data.data.results);
            }
          },
          (error) => {
            if (
              error &&
              error.response &&
              error.response.status &&
              error.response.status === 401
            ) {
              console.log(error);
            } else {
              console.log(error);
            }
          }
        );
    }
  }, [product]);

  return (
    <div className="mt-2">
      <div className="text-md md:text-xl font-extrabold tracking-tight">
        {t('related_products')}
      </div>
      <ItemsCarousel
        placeholderItem={<Placeholder />}
        enablePlaceholder
        numberOfPlaceholderItems={5}
        infiniteLoop
        gutter={12}
        activePosition="left"
        showSlither
        firstAndLastGutter
        activeItemIndex={activeItemIndex}
        requestToChangeActive={setActiveItemIndex}
        numberOfCards={sToScroll(breakpoint)}
        slidesToScroll={sToScroll(breakpoint)}
        rightChevron={
          <button
            type="button"
            className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full"
          >
            <FontAwesomeIcon icon={faArrowToRight} />
          </button>
        }
        leftChevron={
          <button
            type="button"
            className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full"
          >
            <FontAwesomeIcon icon={faArrowToLeft} />
          </button>
        }
        classes={{
          itemsWrapper: 'w-full py-2',
          itemWrapper: 'w-full',
        }}
      >
        {_.map(products, (e) => (
          <Card
            key={e.id}
            product={e}
            name={e.name}
            description={e.description}
            image={(e.images.length > 0 && e.images[0].thumbnailUrl) || null}
            id={e.id}
            brand={e.brand}
            price={e.activePrice}
          />
        ))}
      </ItemsCarousel>
    </div>
  );
}

export default Related;
