import React, { useEffect } from 'react';
import _ from 'lodash';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { storeAtom, userAtom } from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';
import Store1 from './shared-components/Store1';
import Store2 from './shared-components/Store2';
import Store3 from './shared-components/Store3';
import useAnalyticsEventTracker from '../../../useAnalyticsEventTracker';
import api from '../../../api/api';

function Store() {
  const { id } = useParams();
  const [store, setStore] = useRecoilState(storeAtom);
  const eventTracker = useAnalyticsEventTracker('User Actions');
  useEffect(() => {
    eventTracker('Entered Store', store?.name);
  }, []);

  useEffect(() => {
    api({
      method: 'GET',
      url: `store/${id}`,
    })
    .then((response) => {
      setStore(response.data.data);
    })
    .catch((error) => {
      console.log(error)
    });
  }, [id]);

  if (envConfig.StoreVersion === '1') {
    return (
      <Store1 />
    )
  } 
  if (envConfig.StoreVersion === '2') {
    return (
      <Store2 />
    )
  } 
  if (envConfig.StoreVersion === '3') {
    return (
      <Store3 />
    )
  } 
  return (
    <Store1 />
  )
} 

export default Store;
