import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { locationAtom, serviceSelectionAtom, chooseLocationAtom, tokenAtom, notificationsAtom } from '../../../../atoms/Atoms';
import { H2 } from '../../typography/Title';
import Subtitle from '../../typography/Subtitle';
import Button from '../../buttons/Button';
import Loader from '../../loader/Loader';
import SmallDevicesList from './SmallDevicesList';
import MediumDevicesList from './MediumDevicesList';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import api from '../../../../api/api';



function LocationsList() {
  const { t } = useTranslation();
  const [locations, setLocations] = useRecoilState(locationAtom);
  const breakpoint = useBreakpoint();
  const [serviceSelection, setServiceSelection] = useRecoilState(serviceSelectionAtom);
  const [chooseLocation, setChooseLocation] = useRecoilState(serviceSelectionAtom);
  const token = useRecoilValue(tokenAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);


  const getLocations = () => {
    api
      .get('user/locations')
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
                `${t('locations_load_error')}`,
              error: true,
              modal: false,
            },
          ]);
        }

        setLocations({
          ...locations,
          locations: response.data.data.results,
          loading: false,
        });
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description: `${t('locations_load_error')} - ${error.message}`,
            error: true,
          },
        ]);
        setLocations({
          ...locations,
          loading: false,
        });
      });
  };


  useEffect(() => {
    if (token) {
      getLocations();
    }
  }, [token]);


  if (locations.loading && locations.current !== 'register-location') {
    return (
      <motion.div className="flex flex-col flex-1 items-center justify-center">
        <button
          type="button"
          onClick={() => setChooseLocation(false)}
          className="ring-0 focus:ring-0 outline-none focus:outline-none"
        >
          <FontAwesomeIcon icon={faTimesCircle} className="text-2xl" />
        </button>
        <Loader color="bg-accent" />
      </motion.div>
    );
  }

  if (!locations.loading && locations.locations.length === 0) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center rounded rounded-t-lg">        
        <div className="text-center p-4">
          <H2 text={t('no_locations')} />
          <Subtitle text={t('no_locations_prompt')} />
        </div>
        <Button
          text={t('register_location')}
          textColor="text-white"
          className="bg-accent hover:bg-accent w-auto p-2"
          onClick={() => setLocations({ ...locations, modal: true, current: 'register-location' })}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1 items-center justify-center">
      <SmallDevicesList />
    </div>
  );
}

export default LocationsList;
