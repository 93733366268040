import React, { useRef } from 'react';
import { useRecoilState } from 'recoil';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { showShopperAtom, selectedShopperPageAtom } from '../../../atoms/Atoms';
import { backdropVariants } from '../../../utils';
import Shopper1 from './shared-components/Shopper1';
import Shopper2 from './shared-components/Shopper2';
import ShopperImage from './shared-components/ShopperImage';
import envConfig from '../../../envConfig';

function Shopper() {
  const ref = useRef();
  const [showShopper, setShowShopper] = useRecoilState(showShopperAtom);
  const [selectedShopperPage, setSelectedShopperPage] = useRecoilState(selectedShopperPageAtom);

  useClickAway(ref, () => {
    if (!selectedShopperPage) {
      setShowShopper(false);
    }
  });

  return (
    <AnimatePresence>
      {showShopper && (
        <>
          <motion.div
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ zIndex: 1001 }}
            className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
          >
            <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative my-6 mx-auto w-full md:px-32 xl:w-2/3 px-4">
                <div
                  ref={ref}
                  className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
                >
                  <div className="flex items-center justify-end">
                    <button
                      type="button"
                      onClick={() => {
                        setShowShopper(false);
                        setSelectedShopperPage(null);
                      }}
                      className="hover:opacity-75 outline-none focus:outline-none"
                    >
                      <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                    </button>
                  </div>
                  <div className="flex space-x-4 md:mb-10">
                    {!envConfig.ShopperVersion && <Shopper2 />}
                    {envConfig.ShopperVersion === '1' && <Shopper2 />}
                    {envConfig.ShopperVersion === '2' && <Shopper2 />}
                    {envConfig.ShopperVersion === '3' && <Shopper2 />}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          <ShopperImage />
        </>
      )}
    </AnimatePresence>
  )
} 

export default Shopper;
