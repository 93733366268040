import dayjs from 'dayjs';
import visa from './assets/img/credit_cards/visa.svg';
import amex from './assets/img/credit_cards/amex.svg';
import diners from './assets/img/credit_cards/diners.svg';
import mastercard from './assets/img/credit_cards/mastercard.svg';
import discover from './assets/img/credit_cards/discover.svg';
import unknown from './assets/img/credit_cards/unknown.svg';

export const toLocalTime = (date) => {
  if (date) {
    return dayjs(date).subtract(4, 'hour');
  }
  return dayjs.utc().subtract(4, 'hour')
};

export const getType = (card) => {
  switch (card) {
    case 'visa':
      return visa;
    case 'amex':
      return amex;
    case 'american express':
      return amex;
    case 'mastercard':
      return mastercard;
    case 'discover':
      return discover;
    case 'diners':
      return diners;
    default:
      return unknown;
  }
};

export const mapPaymentType = (type) => {
  switch (type) {
    case 0:
      return 'Stripe';
    case 1:
      return 'ATH Movil';
    case 7:
      return 'ATH';
    case 3:
      return 'Other';
    case 4:
      return 'EBT';
    case 5:
      return 'Paypal';
    case 10:
      return 'MCS';
    case 11:
      return 'MMM';
    case 16:
      return 'SSS';
    case 17:
      return 'Humana';
    default:
      return type;
  }
};

export const mapOrderType = (type) => {
  switch (type) {
    case 0:
      return 'Recogido';
    case 1:
      return 'Entrega';
    case 6:
      return 'Deli Pickup';
    case 7:
      return 'Deli Delivery';
    default:
      return 'Undefined';
  }
};

export const mapOrderStatus = (status) => {
  switch (status) {
    case 0:
      return 'Esperando Selección';
    case 2:
      return 'Entrega fue Asignado';
    case 3:
      return 'Esperando Selección';
    case 6:
      return 'Recogido por Cliente';
    case 7:
      return 'Entregado';
    case 8:
      return 'Cancelado por tienda';
    case 9:
      return 'Cancelado';
    case 10:
      return 'Pago fue declinado';
    case 5:
      return 'De camino a ser entregado';
    case 16:
      return 'En proceso de selección';
    case 17:
      return 'Selección finalizada';
    case 18:
      return 'En proceso de empaque';
    case 20:
      return 'Listo para ser recogido';
    case 21:
      return 'Esperando asignación de conductor';
    case 27:
      return 'Pago de paypal pendiente';
    case 30:
      return 'Pago pendiente';
    case 32:
      return 'Bajo revisión';
    case 33:
      return 'IVU Control Failure';
    default:
      return 'Desconocido';
  }
};

export function formatNumber(number) {
  if (number) {
    return number.toLocaleString();
  }
  return '';
}
export const thousand = (number) => {
  if (number === null || number === undefined) {
    return '0';
  }
  if (number) {
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const result = parts.join('.');
    return result;
  }
  return '0';
};
